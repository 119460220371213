import { GlobalExpenseSchema, TYPE } from '../../api/schemas/globalExpenseSchema';
import { t } from '../../i18n';

export const TYPE_LABELS: Record<GlobalExpenseSchema['type'], string> = {
  [TYPE.UNKNOWN]: '—',
  [TYPE.CHIPS]: t('common.chips'),
  [TYPE.FINE]: t('common.fine'),
  [TYPE.IN_GAME]: t('common.in_game'),
  [TYPE.EMOJI]: t('common.emoji'),
  [TYPE.RENAME]: t('common.rename'),
  [TYPE.VIP_CARD]: t('common.vip_card'),
  [TYPE.OTHER]: t('common.other'),
  [TYPE.CLUB_SERVICE]: t('common.club_service'),
  [TYPE.KICK]: t('common.kick'),
  [TYPE.SCAM]: t('common.scam'),
};

export const typesOptions = Object.values(TYPE)
  .filter((item) => item !== TYPE.UNKNOWN)
  .map((item) => ({
    value: item,
    label: TYPE_LABELS[item],
  }));
