import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createGlobalExpense, loadGlobalDeals, updateGlobalExpense } from '../../api';
import { GlobalDealSchema } from '../../api/schemas/deal/globalDealSchema';
import { GlobalExpenseSchema } from '../../api/schemas/globalExpenseSchema';
import Checkbox from '../../components/Checkbox';
import DatePicker from '../../components/DatePicker';
import Input from '../../components/Input';
import LinkButton from '../../components/LinkButton';
import PageAddon from '../../components/PageAddon';
import Select, { Option } from '../../components/Select';
import Textarea from '../../components/Textarea';
import useQuickForm from '../../hooks/useQuickForm';
import useTimeZone from '../../hooks/useTimeZone';
import useURL from '../../hooks/useURL';
import { currenciesOptions, CURRENCY_LABELS } from '../../utils/currency';
import { convertCentsToDollars, convertDollarsToCents, removeCommas } from '../../utils/data';
import { bigNumber, required } from '../../utils/form';
import notify from '../../utils/notify';
import { can } from '../../utils/permissions';
import { renderCaption } from '../deal/helpers';
import { TYPE_LABELS, typesOptions } from './helpers';

interface Fields {
  deal_id: Option<GlobalExpenseSchema['deal']['id']>;
  type: Option<GlobalExpenseSchema['type']>;
  amount: string;
  currency: Option<GlobalExpenseSchema['currency']>;
  reconcile: boolean;
  description: string;
  date: string;
}

interface Args {
  data?: GlobalExpenseSchema;
  onCreate?: (args: { expenseId: GlobalExpenseSchema['id'] }) => void;
  getDealRoute?: (args: { dealId: GlobalDealSchema['id'] }) => string;
}

const useForm = (args: Args) => {
  const { data, onCreate, getDealRoute } = args;

  const { t } = useTranslation();
  const { navigate } = useURL();
  const { dateTimeToZonedDateTime } = useTimeZone();

  const form = useQuickForm<Fields>({
    data,
    defaultValues: {
      deal_id: data
        ? { label: data.deal.code, value: data.deal.id, caption: data.deal.holder?.username }
        : null,
      type: data ? { value: data.type, label: TYPE_LABELS[data.type] } : null,
      amount: data ? String(convertCentsToDollars(data.amount)) : '',
      currency: data ? { value: data.currency, label: CURRENCY_LABELS[data.currency] } : null,
      reconcile: data?.reconcile || false,
      description: data?.description || '',
      date: data?.date || '',
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.currency || !values.type) throw new Error();

    const payload = {
      type: values.type.value,
      amount: convertDollarsToCents(Number(removeCommas(values.amount))),
      currency: values.currency.value,
      reconcile: values.reconcile,
      description: values.description,
    };

    if (data) {
      await updateGlobalExpense({ expenseId: data.id, payload });
    } else {
      if (!values.deal_id) throw new Error();

      const response = await createGlobalExpense({
        payload: {
          ...payload,
          deal_id: values.deal_id.value,
          date: dateTimeToZonedDateTime(values.date),
        },
      });

      if (onCreate) onCreate({ expenseId: response.id });

      notify('success', { title: t('sentences.record_has_been_created') });
    }
  };

  const detail = data && !onCreate;

  const disabled = !can.deal.createUpdate;

  const commonProps = { control, disabled, ...(detail && { onSubmit: handleSubmit(onSubmit) }) };

  return {
    form,
    onSubmit,
    fields: {
      deal_id: (
        <PageAddon.Field label={t('common.deal')}>
          <Select.AsyncV2
            onLoad={(params) => loadGlobalDeals(params)}
            select={(item) => ({
              value: item.id,
              label: item.code,
              caption: renderCaption(item),
            })}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                name="deal_id"
                rules={{ validate: { required } }}
                renderCaption={(option) => option?.caption}
                {...(data?.deal &&
                  getDealRoute && {
                    extra: (
                      <LinkButton
                        size="extra-small"
                        variant="light"
                        onClick={() => navigate(getDealRoute({ dealId: data.deal.id }))}
                      />
                    ),
                  })}
                {...{ ...commonProps, disabled: commonProps.disabled || Boolean(data) }}
                {...selectAsyncProps}
              />
            )}
          </Select.AsyncV2>
        </PageAddon.Field>
      ),
      type: (
        <PageAddon.Field label={t('common.type')}>
          <Select.Quick
            name="type"
            options={typesOptions}
            rules={{ validate: { required } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      amount: (
        <PageAddon.Field label={t('common.amount')}>
          <Input.Quick
            name="amount"
            format={{ decimalScale: 2 }}
            rules={{ validate: { required, max: bigNumber({ decimalScale: 2 }) } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      currency: (
        <PageAddon.Field label={t('common.currency')}>
          <Select.Quick
            name="currency"
            options={currenciesOptions}
            rules={{ validate: { required } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      reconcile: (
        <PageAddon.Field label={t('common.reconcile')}>
          <Checkbox.Quick name="reconcile" {...commonProps} />
        </PageAddon.Field>
      ),
      description: (
        <PageAddon.Field label={t('common.description')}>
          <Textarea.Quick name="description" {...commonProps} />
        </PageAddon.Field>
      ),
      date: (
        <PageAddon.Field label={t('common.date')}>
          <Controller
            control={control}
            name="date"
            rules={{ validate: { required } }}
            render={({ field }) => (
              <DatePicker
                quick
                withTime
                errorVariant="popover"
                value={field.value}
                onChange={(value) => field.onChange(value)}
                error={errors.date?.message}
              />
            )}
          />
        </PageAddon.Field>
      ),
    },
  };
};

export default useForm;
