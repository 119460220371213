import { useTranslation } from 'react-i18next';
import {
  createGlobalBotSettingRule,
  loadGlobalBotSettings,
  loadGlobalWorkspaces,
  updateGlobalBotSettingRule,
} from '../../api';
import { BotSettingRuleSchema } from '../../api/schemas/botSettingRuleSchema';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import Input from '../../components/Input';
import LinkButton from '../../components/LinkButton';
import PageAddon from '../../components/PageAddon';
import Select, { Option } from '../../components/Select';
import Textarea from '../../components/Textarea';
import useQuickForm from '../../hooks/useQuickForm';
import useURL from '../../hooks/useURL';
import { required } from '../../utils/form';
import notify from '../../utils/notify';
import { can } from '../../utils/permissions';
import {
  TRAINER_GAME_TYPE_LABELS,
  TRAINER_POKER_ROOM_LABELS,
  trainerGameTypeOptions,
  trainerPokerRoomOptions,
} from '../../utils/trainer';
import { getBotSettingCaption } from './helpers';

interface Fields {
  name: string;
  game_type: Option<BotSettingRuleSchema['game_type']>;
  maintenance_id: Option<Exclude<BotSettingRuleSchema['maintenance'], null>['id']>;
  bot_settings_id: Option<BotSettingRuleSchema['bot_settings']['id']>;
  poker_room: Option<Exclude<BotSettingRuleSchema['poker_room'], null>>;
  description: string;
}

interface Args {
  data?: BotSettingRuleSchema;
  onCreate?: (args: { botSettingRuleId: BotSettingRuleSchema['id'] }) => void;
  getBotSettingRoute?: (args: { botSettingId: BotSettingSchema['id'] }) => string;
}

const useForm = (args: Args) => {
  const { data, onCreate, getBotSettingRoute } = args;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const form = useQuickForm<Fields>({
    data,
    defaultValues: {
      name: data?.name || '',
      game_type: data
        ? { value: data.game_type, label: TRAINER_GAME_TYPE_LABELS[data.game_type] }
        : null,
      maintenance_id: data?.maintenance
        ? { label: data.maintenance.name, value: data.maintenance.id }
        : null,
      bot_settings_id: data?.bot_settings
        ? { label: data.bot_settings.name, value: data.bot_settings.id }
        : null,
      poker_room: data?.poker_room
        ? { value: data.poker_room, label: TRAINER_POKER_ROOM_LABELS[data.poker_room] }
        : null,
      description: data?.description || '',
    },
  });

  const { control, watch, setValue, handleSubmit } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.game_type) throw new Error();
    if (!values.bot_settings_id) throw new Error();

    const payload = {
      name: values.name,
      game_type: values.game_type.value,
      maintenance_id: values.maintenance_id?.value || null,
      bot_settings_id: values.bot_settings_id.value,
      poker_room: values.poker_room?.value || null,
      description: values.description || null,
    };

    if (data) {
      await updateGlobalBotSettingRule({ botSettingRuleId: data.id, payload });
    } else {
      const response = await createGlobalBotSettingRule({ payload });

      if (onCreate) onCreate({ botSettingRuleId: response.id });

      notify('success', { title: t('sentences.record_has_been_created') });
    }
  };

  const { game_type, maintenance_id } = watch();

  const detail = data && !onCreate;

  const disabled = !can.botSetting.ruleCreateUpdate;

  const commonProps = { control, disabled, ...(detail && { onSubmit: handleSubmit(onSubmit) }) };

  return {
    form,
    onSubmit,
    fields: {
      name: (
        <PageAddon.Field label={t('common.name')}>
          <Input.Quick name="name" rules={{ validate: { required } }} {...commonProps} />
        </PageAddon.Field>
      ),
      game_type: (
        <PageAddon.Field label={t('common.game_type')}>
          <Select.Quick
            name="game_type"
            options={trainerGameTypeOptions}
            rules={{ validate: { required } }}
            onChange={() => {
              setValue('bot_settings_id', null);

              if (commonProps.onSubmit) commonProps.onSubmit();
            }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      maintenance_id: (
        <PageAddon.Field label={t('common.workspace')}>
          <Select.Async
            onLoad={async (params) => {
              const response = await loadGlobalWorkspaces(params);

              const options = response.items.map((item) => ({
                value: item.id,
                label: item.name,
              }));

              return options;
            }}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                name="maintenance_id"
                onChange={() => {
                  setValue('bot_settings_id', null);

                  if (commonProps.onSubmit) commonProps.onSubmit();
                }}
                {...commonProps}
                {...selectAsyncProps}
                {...{ ...commonProps, disabled: commonProps.disabled || !game_type }}
              />
            )}
          </Select.Async>
        </PageAddon.Field>
      ),
      bot_settings_id: (
        <PageAddon.Field label={t('common.bot_settings')}>
          <Select.Async
            onLoad={async (params) => {
              const response = await loadGlobalBotSettings({
                ...params,
                ...(game_type && { game_type_in: String(game_type.value) }),
                ...(!maintenance_id && { show_null_maintenance: 'true' }),
                ...(maintenance_id && { maintenance_in_or_null: maintenance_id.value }),
              });

              const options = response.items.map((item) => ({
                value: item.id,
                label: item.name,
                caption: getBotSettingCaption(item),
              }));

              return options;
            }}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                name="bot_settings_id"
                rules={{ validate: { required } }}
                onChange={() => {
                  if (commonProps.onSubmit) commonProps.onSubmit();
                }}
                {...(data?.bot_settings &&
                  getBotSettingRoute && {
                    extra: (
                      <LinkButton
                        size="extra-small"
                        variant="light"
                        onClick={() =>
                          navigate(getBotSettingRoute({ botSettingId: data.bot_settings.id }))
                        }
                      />
                    ),
                  })}
                {...commonProps}
                {...selectAsyncProps}
                {...{ ...commonProps, disabled: commonProps.disabled || !game_type }}
              />
            )}
          </Select.Async>
        </PageAddon.Field>
      ),
      poker_room: (
        <PageAddon.Field label={t('common.poker_room')}>
          <Select.Quick
            name="poker_room"
            options={trainerPokerRoomOptions}
            {...commonProps}
            {...{ ...commonProps, disabled: commonProps.disabled || !game_type }}
          />
        </PageAddon.Field>
      ),
      description: (
        <PageAddon.Field label={t('common.description')}>
          <Textarea.Quick name="description" {...commonProps} />
        </PageAddon.Field>
      ),
    },
  };
};

export default useForm;
