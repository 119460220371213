import { useTranslation } from 'react-i18next';
import {
  createWorkspaceAccount,
  loadWorkspaceBotSettings,
  loadWorkspaceChannels,
  loadWorkspaceClubs,
  loadWorkspaceIdentities,
  loadWorkspaceTags,
  loadWorkspaceUsers,
  updateWorkspaceAccount,
} from '../../api';
import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import { ChannelSchema, STATUS } from '../../api/schemas/channelSchema';
import { WorkspaceDealSchema } from '../../api/schemas/deal/workspaceDealSchema';
import { IdentitySchema } from '../../api/schemas/identitySchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import { WorkspaceTagSchema } from '../../api/schemas/workspaceTagSchema';
import { WorkspaceUserSchema } from '../../api/schemas/workspaceUserSchema';
import Input from '../../components/Input';
import LinkButton from '../../components/LinkButton';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import { Option } from '../../components/Select/types';
import Textarea from '../../components/Textarea';
import useQuickForm from '../../hooks/useQuickForm';
import useURL from '../../hooks/useURL';
import useWorkspace from '../../hooks/useWorkspace';
import icon from '../../static/icons';
import { email, max, maxLength, minLength, required } from '../../utils/form';
import notify from '../../utils/notify';
import {
  getTrainerPokerRoomOptions,
  TRAINER_GAME_TYPE_LABELS,
  TRAINER_LIMITS_LABELS,
  TRAINER_POKER_ROOM_LABELS,
  trainerGameTypeOptions,
} from '../../utils/trainer';
import { numbers } from '../../utils/validations';
import AccountDetailBotSettings from '../account/AccountDetailBotSettings';
import AccountDetailChannels from '../account/AccountDetailChannels';
import AccountDetailClubs from '../account/AccountDetailClubs';
import AccountGeolocationDialog from '../account/AccountGeolocationDialog';
import {
  getGeolocationAsString,
  limitOptions,
  TABLE_SIZE_LABELS,
  tableSizeOptions,
} from '../account/helpers';
import { getIdentityFullName } from '../workspace-identity/helpers';
import { getChannelsValue } from './helpers';
import { Fields } from './types';
import WorkspaceAccountColorCircle from './WorkspaceAccountColorCircle';
import WorkspaceAccountDetailDeals from './WorkspaceAccountDetailDeals';

const getDefaultValues = (data?: WorkspaceAccountSchema) => ({
  room: data ? { value: data.room, label: TRAINER_POKER_ROOM_LABELS[data.room] } : null,
  login: data?.login || '',
  password: '',
  club_ids: data
    ? data.clubs.map((item) => ({ value: item.id, label: item.name, caption: item.code }))
    : [],
  stopped_club_ids: data ? data.stopped_clubs.map((item) => item.id) : [],
  game_types: data
    ? data.game_types.map((item) => ({
        value: item,
        label: TRAINER_GAME_TYPE_LABELS[item],
      }))
    : [],
  comparative_limits: data
    ? data.comparative_limits.map((item) => ({
        value: item,
        label: TRAINER_LIMITS_LABELS[item],
      }))
    : [],

  table_sizes: data
    ? data.table_sizes.map((item) => ({
        value: item,
        label: TABLE_SIZE_LABELS[item],
      }))
    : [],
  max_tables: data ? String(data.max_tables) : '',
  geo: data ? getGeolocationAsString(data.geo) : '',
  channel_ids: data ? getChannelsValue(data.channels) : [],
  bot_settings_ids: data
    ? data.bot_settings.map((item) => ({ value: item.id, label: item.name }))
    : [],
  deal_ids: data ? data.deals.map((item) => ({ value: item.id, label: item.code })) : [],
  affiliate_reference_number: data?.affiliate_reference_number || '',
  user_id: data?.user ? { value: data.user.id, label: data.user.username } : null,
  identity_id: data?.identity
    ? { value: data.identity.id, label: getIdentityFullName(data.identity) }
    : null,
  tag_ids: data
    ? data.tags.map((item) => ({
        value: item.id,
        label: item.name,
        meta: item,
      }))
    : [],
  email: data?.email || '',
  email_password: data?.email_password || '',
  nickname_pool: '',
});

const getPayload = (values: Fields) => {
  const [lat, lng] = values.geo.split(',');

  return {
    login: values.login,
    password: values.password,
    club_ids: values.club_ids.map((item) => item.value),
    stopped_club_ids: values.stopped_club_ids,
    game_types: values.game_types.map((item) => item.value),
    comparative_limits: values.comparative_limits.map((item) => item.value),
    table_sizes: values.table_sizes.map((item) => item.value),
    max_tables: Number(values.max_tables),
    geo: values.geo ? { lat: Number(lat), lng: Number(lng) } : null,
    channel_ids: values.channel_ids.map((item) => item.value),
    bot_settings_ids: values.bot_settings_ids.map((item) => item.value),
    deal_ids: values.deal_ids.map((item) => item.value),
    affiliate_reference_number: values.affiliate_reference_number || null,
    user_id: values.user_id?.value || null,
    identity_id: values.identity_id?.value || null,
    tag_ids: values.tag_ids.map((item) => item.value),
    email: values.email || null,
    email_password: values.email_password || null,
    nickname_pool: values.nickname_pool,
  };
};

interface Args {
  workspaceId: WorkspaceSchema['id'];
  data?: WorkspaceAccountSchema;
  bulk?: boolean;
  required?: boolean;
  onCreate?: (args: { accountId: WorkspaceAccountSchema['id'] }) => void;
}

const useForm = (args: Args) => {
  const { workspaceId, data, bulk, required: req, onCreate } = args;

  const { t } = useTranslation();
  const { navigate } = useURL();

  const workspace = useWorkspace();

  const roomOptions = getTrainerPokerRoomOptions(workspace.poker_rooms);

  let defaultValues = getDefaultValues(data);

  defaultValues = { ...defaultValues, ...(!data && { room: roomOptions[0] }) };

  const form = useQuickForm<Fields>({ data, defaultValues });

  const { control, watch, setValue, handleSubmit } = form;

  const onSubmit = async (values: Fields) => {
    const payload = getPayload(values);

    if (data) {
      await updateWorkspaceAccount({
        workspaceId,
        accountId: data.id,
        payload: {
          club_ids: payload.club_ids,
          stopped_club_ids: payload.stopped_club_ids,
          game_types: payload.game_types,
          comparative_limits: payload.comparative_limits,
          table_sizes: payload.table_sizes,
          max_tables: payload.max_tables,
          geo: payload.geo,
          channel_ids: payload.channel_ids,
          bot_settings_ids: payload.bot_settings_ids,
          deal_ids: payload.deal_ids,
          affiliate_reference_number: payload.affiliate_reference_number,
          user_id: payload.user_id,
          identity_id: payload.identity_id,
          tag_ids: payload.tag_ids,
          email: payload.email,
          email_password: payload.email_password,
        },
      });
    } else {
      if (!values.room) throw new Error();

      const response = await createWorkspaceAccount({
        workspaceId,
        payload: {
          room: values.room.value,
          login: payload.login,
          password: payload.password,
        },
      });

      if (onCreate) onCreate({ accountId: response.id });

      notify('success', { title: t('sentences.record_has_been_created') });
    }
  };

  const room = watch('room')?.value;
  const clubIds = watch('club_ids');
  const stoppedClubIds = watch('stopped_club_ids');
  const channelIds = watch('channel_ids');
  const botSettingIds = watch('bot_settings_ids');
  const dealIds = watch('deal_ids');

  const detail = data && !onCreate;

  const commonProps = { control, ...(detail && { onSubmit: handleSubmit(onSubmit) }) };

  return {
    form,
    onSubmit,
    defaultValues,
    getPayload,
    fields: {
      room: ({ onRoomChange }: { onRoomChange?: () => void } = {}) => (
        <PageAddon.Field label={t('common.poker_room')}>
          <Select.Quick
            name="room"
            options={roomOptions}
            disabled={roomOptions.length === 1}
            rules={{ validate: { required } }}
            onChange={() => {
              setValue('game_types', []);
              setValue('comparative_limits', []);
              setValue('deal_ids', []);

              if (onRoomChange) onRoomChange();
            }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      login: (
        <PageAddon.Field label={t('common.login')}>
          <Input.Quick
            name="login"
            rules={{ validate: { required, minLength: minLength(), maxLength: maxLength() } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      password: (
        <PageAddon.Field label={t('common.password')}>
          <Input.Quick
            name="password"
            type="password"
            rules={{ validate: { required, minLength: minLength() } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      club_ids: (
        <PageAddon.Field label={t('common.clubs')}>
          <AccountDetailClubs
            data={
              data
                ? {
                    clubs: data.clubs.map((item) => ({
                      id: item.id,
                      name: item.name,
                      code: item.code,
                    })),
                    stopped_clubs: data.stopped_clubs.map((item) => ({ id: item.id })),
                  }
                : {
                    clubs: clubIds.map((item) => ({ id: item.value, name: item.label })),
                    stopped_clubs: stoppedClubIds.map((item) => ({ id: item })),
                  }
            }
            required={req}
            onSubmit={() => {
              if (commonProps.onSubmit) commonProps.onSubmit();
            }}
            onLoad={(params) =>
              loadWorkspaceClubs(
                { workspaceId },
                { ...params, ...(!bulk && { poker_room_in: `${room}` }) }
              )
            }
          />
        </PageAddon.Field>
      ),
      game_types: (
        <PageAddon.Field label={t('common.game_types')}>
          <Select.Multi.Quick
            name="game_types"
            options={trainerGameTypeOptions}
            {...(req && { rules: { validate: required } })}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      comparative_limits: (
        <PageAddon.Field label={t('common.limits')}>
          <Select.Multi.Quick
            selectAll
            name="comparative_limits"
            options={limitOptions}
            {...(req && { rules: { validate: required } })}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      table_sizes: (
        <PageAddon.Field label={t('common.table_size')}>
          <Select.Multi.Quick
            name="table_sizes"
            options={tableSizeOptions}
            {...(req && { rules: { validate: required } })}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      max_tables: (
        <PageAddon.Field label={t('common.max_tables')}>
          <Input.Quick
            name="max_tables"
            allow={numbers}
            rules={{ validate: { max: max(t('common.max_tables'), 5), ...(req && required) } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      geo: (
        <PageAddon.Field label={t('common.geolocation')}>
          <Input.Quick
            name="geo"
            extra={
              <AccountGeolocationDialog
                quick
                onChange={() => {
                  if (commonProps.onSubmit) commonProps.onSubmit();
                }}
              />
            }
            {...(req && { rules: { validate: required } })}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      channel_ids: ({
        getChannelRoute,
        onChannelsChange,
      }: {
        getChannelRoute?: (params: { channelId: ChannelSchema['id'] }) => string;
        onChannelsChange?: () => void;
      } = {}) => (
        <PageAddon.Field label={t('common.channels')}>
          <AccountDetailChannels
            data={
              data
                ? {
                    channels: data.channels.map((item) => ({
                      id: item.id,
                      host: item.host,
                      port: item.port,
                      status: item.status,
                    })),
                  }
                : {
                    channels: channelIds.map((item) => ({
                      id: item.value,
                      host: item.label,
                      ...(item.meta && { port: item.meta.port, status: item.meta.status }),
                    })),
                  }
            }
            onDelete={({ channelId }) => {
              setValue(
                'channel_ids',
                channelIds.filter((el) => el.value !== channelId)
              );

              if (commonProps.onSubmit) commonProps.onSubmit();
              if (onChannelsChange) onChannelsChange();
            }}
            {...(getChannelRoute && {
              controls: ({ channelId }) => (
                <LinkButton
                  size="extra-small"
                  variant="light"
                  onClick={() => navigate(getChannelRoute({ channelId }))}
                />
              ),
            })}
          >
            <Select.AsyncV2
              onLoad={(params) =>
                loadWorkspaceChannels({ workspaceId }, { ...params, is_free: 'true' })
              }
              select={(item) => {
                const inactive = item.status === STATUS.UNKNOWN || item.status === STATUS.INACTIVE;

                return {
                  value: item.id,
                  label: item.host,
                  danger: inactive,
                  meta: item,
                  ...(item.country && { caption: item.country }),
                  ...(inactive && { endIcon: icon('alertTriangle', 16) }),
                };
              }}
            >
              {({ options, ...selectAsyncProps }) => (
                <Select.Quick
                  name="channel_ids"
                  options={options.filter(
                    (item) => !channelIds.map((el) => el.value).includes(item.value)
                  )}
                  onChange={(value) => {
                    setValue('channel_ids', value ? [...channelIds, value] : []);

                    if (commonProps.onSubmit) commonProps.onSubmit();

                    if (onChannelsChange) onChannelsChange();
                  }}
                  {...(req &&
                    (!data?.channels.length || !channelIds.length) && {
                      rules: { validate: required },
                    })}
                  {...selectAsyncProps}
                />
              )}
            </Select.AsyncV2>
          </AccountDetailChannels>
        </PageAddon.Field>
      ),
      bot_settings_ids: ({
        getBotSettingRoute,
      }: {
        getBotSettingRoute?: (params: { botSettingId: BotSettingSchema['id'] }) => string;
      } = {}) => (
        <PageAddon.Field label={t('common.bot_settings')}>
          <AccountDetailBotSettings
            data={
              data
                ? {
                    bot_settings: data.bot_settings.map((item) => ({
                      id: item.id,
                      name: item.name,
                      game_type: item.game_type,
                    })),
                  }
                : {
                    bot_settings: botSettingIds.map((item) => ({
                      id: item.value,
                      name: item.label,
                    })),
                  }
            }
            required={req}
            onSubmit={() => {
              if (commonProps.onSubmit) commonProps.onSubmit();
            }}
            onLoad={(params) => loadWorkspaceBotSettings({ workspaceId }, params)}
            {...(getBotSettingRoute && {
              controls: ({ botSettingId }) => (
                <LinkButton
                  size="extra-small"
                  variant="light"
                  onClick={() => navigate(getBotSettingRoute({ botSettingId }))}
                />
              ),
            })}
          />
        </PageAddon.Field>
      ),
      deal_ids: ({
        getDealRoute,
      }: {
        getDealRoute?: (params: { dealId: WorkspaceDealSchema['id'] }) => string;
      } = {}) => (
        <PageAddon.Field label={t('common.deals')}>
          <WorkspaceAccountDetailDeals
            workspaceId={workspaceId}
            data={
              data
                ? {
                    room: data.room,
                    clubIds: data.clubs.map((item) => item.id),
                    deals: data.deals.map((item) => ({
                      id: item.id,
                      code: item.code,
                      agent: item.id,
                    })),
                  }
                : {
                    room: room || roomOptions[0].value,
                    clubIds: clubIds.map((item) => item.value),
                    deals: dealIds.map((item) => ({ id: item.value, code: item.label })),
                  }
            }
            required={req}
            bulk={bulk}
            onSubmit={() => {
              if (commonProps.onSubmit) commonProps.onSubmit();
            }}
            getDealRoute={getDealRoute}
          />
        </PageAddon.Field>
      ),
      affiliate_reference_number: (
        <PageAddon.Field label={t('common.affiliate')}>
          <Input.Quick
            name="affiliate_reference_number"
            {...(req && { rules: { validate: required } })}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      user_id: ({
        getUserRoute,
      }: {
        getUserRoute?: (params: { userId: WorkspaceUserSchema['id'] }) => string;
      } = {}) => (
        <PageAddon.Field label={t('common.assign_to')}>
          <Select.AsyncV2
            onLoad={(params) => loadWorkspaceUsers({ workspaceId }, params)}
            select={(item) => ({ value: item.id, label: item.username })}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                clearable
                name="user_id"
                {...(data?.user &&
                  getUserRoute && {
                    extra: (
                      <LinkButton
                        size="extra-small"
                        variant="light"
                        onClick={() => {
                          if (data.user) navigate(getUserRoute({ userId: data.user.id }));
                        }}
                      />
                    ),
                  })}
                {...(req && { rules: { validate: required } })}
                {...commonProps}
                {...selectAsyncProps}
              />
            )}
          </Select.AsyncV2>
        </PageAddon.Field>
      ),
      identity_id: ({
        getIdentityRoute,
      }: {
        getIdentityRoute?: (params: { identityId: IdentitySchema['id'] }) => string;
      } = {}) => (
        <PageAddon.Field label={t('common.identity')}>
          <Select.AsyncV2
            onLoad={(params) => loadWorkspaceIdentities({ workspaceId }, params)}
            select={(item) => ({ value: item.id, label: getIdentityFullName(item) })}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                clearable
                name="identity_id"
                {...(data?.identity &&
                  getIdentityRoute && {
                    extra: (
                      <LinkButton
                        size="extra-small"
                        variant="light"
                        onClick={() => {
                          if (data.identity) {
                            navigate(getIdentityRoute({ identityId: data.identity.id }));
                          }
                        }}
                      />
                    ),
                  })}
                {...(req && { rules: { validate: required } })}
                {...commonProps}
                {...selectAsyncProps}
              />
            )}
          </Select.AsyncV2>
        </PageAddon.Field>
      ),
      tag_ids: (
        <PageAddon.Field label={t('common.tags')}>
          <Select.AsyncV2
            onLoad={(params) => loadWorkspaceTags({ workspaceId }, params)}
            select={(item) => ({ value: item.id, label: item.name, meta: item })}
          >
            {({ options, ...selectAsyncProps }) => {
              const getIcon = (option: Option<WorkspaceTagSchema['id'], WorkspaceTagSchema>) => {
                if (!option?.meta) return null;

                return <WorkspaceAccountColorCircle color={option.meta.color} />;
              };

              return (
                <Select.Multi.Quick
                  name="tag_ids"
                  options={options}
                  renderOptionIcon={getIcon}
                  tagExtra={getIcon}
                  {...(req && { rules: { validate: required } })}
                  {...commonProps}
                  {...selectAsyncProps}
                />
              );
            }}
          </Select.AsyncV2>
        </PageAddon.Field>
      ),
      email: (
        <PageAddon.Collapse label={t('common.email')}>
          <PageAddon.Collapse.Field label={t('common.email')}>
            <Input.Quick
              name="email"
              rules={{ validate: email, ...(req && required) }}
              {...commonProps}
            />
          </PageAddon.Collapse.Field>
          <PageAddon.Collapse.Field label={t('common.password')}>
            <Input.Quick
              name="email_password"
              type="password"
              {...(req && { rules: { validate: required } })}
              {...commonProps}
            />
          </PageAddon.Collapse.Field>
        </PageAddon.Collapse>
      ),
      nickname_pool: (
        <PageAddon.Field
          label={t('common.nickname_pool')}
          tooltip={t('sentences.enter_nicknames_separated_by_commas')}
        >
          <Textarea.Quick
            name="nickname_pool"
            {...(req && { rules: { validate: required } })}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
    },
  };
};

export default useForm;
