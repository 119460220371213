import { nanoid } from 'nanoid';
import { ReactNode, isValidElement } from 'react';
import {
  Field,
  isOptionGroupArray,
  RuleGroupType,
  transformQuery,
  uniqOptList,
  FullOptionList,
  FullOption,
} from 'react-querybuilder';
import { QuerySchema } from '../../api/schemas/querySchema';
import CURRENCY from '../../constants/CURRENCY';
import { t } from '../../i18n';
import icon from '../../static/icons';
import { isObject } from '../../utils';
import { convertCentsToDollars, convertDollarsToCents } from '../../utils/data';
import { trainerGameTypeOptions } from '../../utils/trainer';
import { Props as SelectProps } from '../Select/types';

const inOperators = [
  { name: 'in', label: 'in', icon: icon('in', 16) },
  { name: 'not_in', label: 'not in', icon: icon('notIn', 16) },
];

const equalOperator = { name: '=', label: 'eq', icon: icon('eq', 16) };
const notEqualOperator = { name: '!=', label: 'not eq', icon: icon('notEq', 16) };

const comparisonOperators = [
  { name: '<', label: 'le', icon: icon('le', 16) },
  { name: '<=', label: 'lte', icon: icon('lte', 16) },
  { name: '>', label: 'ge', icon: icon('ge', 16) },
  { name: '>=', label: 'gte', icon: icon('gte', 16) },
];

const idOperators = [equalOperator, notEqualOperator, ...inOperators];

export const fields: Field[] = [
  { name: 'min_buy_in_bb', label: t('common.min_buy_in'), group: t('common.common') },
  { name: 'max_buy_in_bb', label: t('common.max_buy_in') },
  { name: 'table_size', label: t('common.table_size') },
  { name: 'small_blind', label: t('common.sb') },
  { name: 'big_blind', label: t('common.bb') },
  {
    name: 'ante',
    label: t('common.ante'),
  },
  { name: 'empty_seats', label: t('common.empty_seats') },
  { name: 'players', label: t('common.players') },
  {
    name: 'game_type_id',
    label: t('common.game_type'),
    operators: idOperators,
  },
  { name: 'waiting_count', label: t('common.waiting_count') },
  {
    name: 'club_id',
    label: t('common.club'),
    operators: idOperators,
  },
  { name: 'hands_per_hour', label: t('common.h/hr'), group: 'PartyPoker' },
  { name: 'avg_pot', label: `${t('common.avg_pot')}, ${CURRENCY.USD}` },
  {
    name: 'real_name_table',
    label: t('common.real_name_table'),
    operators: [equalOperator],
    defaultValue: true,
  },
  { name: 'flop_seen', label: t('common.plrs/flop_%') },
  {
    name: 'ff_pool',
    label: t('common.ff_pool'),
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'allow_player_to_sit',
    label: t('common.allow_to_sit'),
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'early_bird_num',
    label: 'Early bird num',
  },
  {
    name: 'is_started',
    label: 'Is started',
    operators: [equalOperator],
    defaultValue: true,
    group: 'PPPoker',
  },
  {
    name: 'is_hunter',
    label: 'Is hunter',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'gps_limit',
    label: 'GPS limit',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'ip_limit',
    label: 'IP limit',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'is_in',
    label: 'Is in',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'is_insurance',
    label: 'Is insurance',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'vpip_limit',
    label: 'Vpip limit',
  },
  { name: 'protection_level', label: t('common.protection_level') },
  {
    name: 'vpip_limit_per_set',
    label: 'Vpip limit per set',
  },
  {
    name: 'min_hands_per_set',
    label: 'Min hands per set',
  },
  {
    name: 'password_limit',
    label: 'Password limit',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'is_freeze_out',
    label: 'Is freeze out',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'is_top',
    label: 'Is top',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'is_captcha',
    label: 'Is captcha',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'is_ban_pc',
    label: 'Is ban pc',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'is_check_mail',
    label: 'Is check mail',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'table_ttl',
    label: 'Table ttl',
  },
  {
    name: 'is_ppsr',
    label: 'Is ppsr',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'ppsrid',
    label: 'pppsr id',
    operators: idOperators,
  },
  {
    name: 'Status',
    label: 'Status',
    operators: idOperators,
    group: 'Pokerrrr2',
  },
  { name: 'MinStack', label: 'Min stack' },
  {
    name: 'NeedQueue',
    label: 'Need queue',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'Confirmation',
    label: 'Confirmation',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'RunItTwice',
    label: 'Run it twice',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'RabbitHunting',
    label: 'Rabbit hunting',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'NewBlindRule',
    label: 'New blind rule',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'AllowBombPot',
    label: 'Allow bomb pot',
    operators: [equalOperator],
    defaultValue: true,
  },
  { name: 'BombPotHandInterval', label: 'Bomb pot hand interval' },
  {
    name: 'Straddle',
    label: 'Straddle',
    operators: [equalOperator],
    defaultValue: true,
  },
  {
    name: 'BlindType',
    label: 'Blind type',
    operators: idOperators,
  },
  {
    name: 'StraddleType',
    label: 'Straddle type',
    operators: idOperators,
  },
  {
    name: 'RunItTwiceType',
    label: 'Run it twice type',
    operators: idOperators,
  },
];

export const operators = [equalOperator, notEqualOperator, ...comparisonOperators, ...inOperators];

export const gameTypeIdOptions = trainerGameTypeOptions.map((item) => ({
  ...item,
  value: String(item.value),
}));

export const statusOptions = [
  { value: 'handend', label: 'Handend' },
  { value: 'playing', label: 'Playing' },
  { value: 'waiting', label: 'Waiting' },
  { value: 'pause', label: 'Pause' },
  { value: 'prepare', label: 'Prepare' },
  { value: 'end', label: 'End' },
];

export const blindTypeOptions = [
  { value: 'regular', label: 'Regular' },
  { value: 'progressive', label: 'Progressive' },
];

export const straddleTypeOptions = [
  { value: 'normal', label: 'Normal' },
  { value: 'mandatory', label: 'Mandatory' },
];

export const runItTwiceTypeOptions = [
  { value: 'always', label: 'Always' },
  { value: 'everyhand', label: 'Every hand' },
];

export const checkIsFloatField = (field: string) =>
  ['min_buy_in_bb', 'max_buy_in_bb', 'small_blind', 'big_blind', 'avg_pot'].includes(field);

export const checkIsInOperator = (operator: string) => operator === 'in' || operator === 'not_in';

export const checkIsBooleanField = (field: string) =>
  [
    'ff_pool',
    'allow_player_to_sit',
    'real_name_table',
    'is_started',
    'is_hunter',
    'gps_limit',
    'ip_limit',
    'is_in',
    'is_insurance',
    'password_limit',
    'is_freeze_out',
    'is_top',
    'is_captcha',
    'is_ban_pc',
    'is_check_mail',
    'is_ppsr',
    'NeedQueue',
    'Confirmation',
    'RunItTwice',
    'RabbitHunting',
    'NewBlindRule',
    'AllowBombPot',
    'Straddle',
  ].includes(field);

export const mapQuery = (query: QuerySchema): RuleGroupType => ({
  id: nanoid(),
  combinator: query.combinator,
  rules: query.rules.map((item) => {
    if ('rules' in item) return mapQuery(item);

    const getValue = () => {
      const { value, operator, field } = item;

      const isFloatField = checkIsFloatField(field);
      const isInOperator = checkIsInOperator(operator);

      if (isFloatField) {
        if (isInOperator) {
          return String(value)
            .split(',')
            .map((el) => convertCentsToDollars(Number(el)))
            .join(',');
        }

        return String(convertCentsToDollars(Number(value)));
      }

      if (
        field === 'game_type_id' ||
        field === 'Status' ||
        field === 'BlindType' ||
        field === 'StraddleType' ||
        field === 'RunItTwiceType'
      ) {
        const getOptions = () => {
          if (field === 'game_type_id') return gameTypeIdOptions;
          if (field === 'Status') return statusOptions;
          if (field === 'BlindType') return blindTypeOptions;
          if (field === 'StraddleType') return straddleTypeOptions;
          if (field === 'RunItTwiceType') return runItTwiceTypeOptions;

          return [];
        };

        const options = getOptions();

        if (Array.isArray(value)) {
          return value.map((el) => {
            const option = options.find((e) => e.value === String(el));

            return {
              value: option?.value,
              label: option?.label,
            };
          });
        }

        return {
          value: options.find((el) => el.value === String(value))?.value,
          label: options.find((el) => el.value === String(value))?.label,
        };
      }

      if (field === 'ante') return String(convertCentsToDollars(Number(value)));

      if (isInOperator && Array.isArray(value)) return value.join(',');

      return item.value;
    };

    return {
      id: nanoid(),
      field: item.field,
      operator: item.operator,
      value: getValue(),
    };
  }),
});

export const prepareQuery = (query: RuleGroupType) =>
  transformQuery(query, {
    ruleGroupProcessor: (item) => ({ combinator: item.combinator, rules: item.rules }),
    ruleProcessor: (item) => {
      const getValue = () => {
        const { field, operator, value } = item;

        const isFloatField = checkIsFloatField(field);
        const isInOperator = checkIsInOperator(operator);

        if (typeof value === 'string') {
          if (isFloatField) {
            if (isInOperator) {
              return value
                .split(',')
                .map((el) => (el === '' ? '' : convertDollarsToCents(Number(el))));
            }

            return value === '' ? '' : convertDollarsToCents(Number(value));
          }

          if (isInOperator) return value.split(',');

          if (field === 'ante') return String(convertDollarsToCents(Number(value)));

          return value;
        }

        if (isObject(value)) {
          if (field === 'game_type_id') return Number(value.value);
          if (field === 'Status') return value.value;
          if (field === 'BlindType') return value.value;
          if (field === 'StraddleType') return value.value;
          if (field === 'RunItTwiceType') return value.value;

          return value.value;
        }

        if (Array.isArray(value)) return value.map((el) => el.value);

        return value;
      };

      return {
        field: item.field,
        operator: item.operator,
        value: getValue(),
      };
    },
  });

export const selectConfig: SelectProps['config'] = {
  flip: {
    padding: {
      bottom: 64,
    },
  },
};

/* https://github.com/react-querybuilder/react-querybuilder/blob/main/packages/mantine/src/utils.ts */
export const optionListMapNameToValue = (
  list: FullOptionList<FullOption>
): { name: string; value: string; label: string; icon?: ReactNode; group?: string }[] => {
  const uniqList = uniqOptList(list);

  return isOptionGroupArray(uniqList)
    ? []
    : uniqList.map((opt) => ({
        name: opt.name,
        value: opt.name,
        label: opt.label,
        ...(isValidElement(opt.icon) && { icon: opt.icon }),
        ...(typeof opt.group !== 'undefined' && { group: String(opt.group) }),
      }));
};
