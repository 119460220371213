import { z } from 'zod';
import currencySchema from './currencySchema';
import dateTimeSchema from './dateTimeSchema';
import { holderSchema } from './deal';
import globalDealSchema from './deal/globalDealSchema';

export const TYPE = {
  UNKNOWN: 0,
  CHIPS: 1,
  FINE: 2,
  IN_GAME: 3,
  EMOJI: 4,
  RENAME: 5,
  VIP_CARD: 6,
  OTHER: 7,
  CLUB_SERVICE: 8,
  KICK: 9,
  SCAM: 10,
} as const;

const globalExpenseSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(TYPE),
  deal: globalDealSchema,
  amount: z.number(),
  currency: currencySchema,
  reconcile: z.boolean(),
  description: z.string().or(z.null()),
  date: dateTimeSchema,
  is_deleted: z.boolean(),
  created_on: dateTimeSchema,
  modified_on: dateTimeSchema,
});

export const globalExpenseFilterValuesSchema = z.object({
  deal: z.array(
    z.object({
      id: globalExpenseSchema.shape.deal.shape.id,
      name: globalExpenseSchema.shape.deal.shape.code,
    })
  ),
  type: z.array(globalExpenseSchema.shape.type),
  poker_room: z.array(globalExpenseSchema.shape.deal.shape.poker_room),
  club: z.array(
    z.object({
      id: globalExpenseSchema.shape.deal.shape.club.shape.id,
      name: globalExpenseSchema.shape.deal.shape.club.shape.name,
      code: globalExpenseSchema.shape.deal.shape.club.shape.code,
    })
  ),
  agent: z.array(
    z.object({
      id: globalExpenseSchema.shape.deal.shape.agent.shape.id,
      name: globalExpenseSchema.shape.deal.shape.agent.shape.name,
    })
  ),
  holder: z.array(
    z.object({
      id: holderSchema.shape.id,
      name: holderSchema.shape.username,
    })
  ),
});

export type GlobalExpenseSchema = z.infer<typeof globalExpenseSchema>;

export default globalExpenseSchema;
