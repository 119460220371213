import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { loadGlobalBotSettings } from '../../api';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import Card from '../../components/Card';
import FadingText from '../../components/FadingText';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import PageTop from '../../components/PageTop';
import Table, { getTableProps } from '../../components/Table';
import queryKeys from '../../config/queryKeys';
import routes from '../../config/routes';
import { GlobalBotSettingProvider } from '../../features/global-bot-setting/GlobalBotSettingContext';
import GlobalBotSettingCreateButton from '../../features/global-bot-setting/GlobalBotSettingCreateButton';
import GlobalBotSettingDetailLink from '../../features/global-bot-setting/GlobalBotSettingDetailLink';
import GlobalBotSettingDropdown from '../../features/global-bot-setting/GlobalBotSettingDropdown';
import GlobalBotSettingFilter from '../../features/global-bot-setting/GlobalBotSettingFilter';
import {
  getBotCode,
  getBotBuildTight,
  getBotCodeTight,
  getWorkspace,
} from '../../features/global-bot-setting/helpers';
import useBreakpoints from '../../hooks/useBreakpoints';
import usePaginatedData from '../../hooks/usePaginatedData';
import useURL from '../../hooks/useURL';
import useWebSocketPaginatedActions from '../../hooks/useWebSocketPaginatedActions';
import { addWhen } from '../../utils';
import { RouteParams } from '../../utils/generics';
import { can } from '../../utils/permissions';
import { TRAINER_GAME_TYPE_LABELS } from '../../utils/trainer';
import Error403Page from '../Error403Page';
import styles from './styles.module.scss';

type Params = RouteParams<typeof routes.globalBotSetting>;

const GlobalBotSettingsPage = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isPhone } = useBreakpoints();
  const { botSettingId } = useParams<Params>();
  const { urlParams } = useURL();
  const { search } = urlParams;

  const queryKey = [queryKeys.globalBotSettings, urlParams];

  const query = usePaginatedData({
    queryKey,
    queryFn: (params) => loadGlobalBotSettings({ ...urlParams, ...params }),
  });

  const { data, total, loading, error, checkIsInView } = query;

  const { checkIsCreated, ...meta } = useWebSocketPaginatedActions<BotSettingSchema>({
    queryKey,
    domain: 'GlobalBotSettings',
    select: (item) => item,
  });

  if (error?.response?.status === 403) return <Error403Page />;

  return (
    <GlobalBotSettingProvider>
      <Page
        heading={t('common.bot_settings')}
        tabs={[
          ...addWhen(
            { to: routes.globalBotSettings, label: t('common.bot_settings'), active: true },
            can.botSetting.read
          ),
          ...addWhen(
            { to: routes.globalBotSettingsRules, label: t('common.usage_rules') },
            can.botSetting.ruleRead
          ),
        ]}
        extra={
          <PageControls>
            <GlobalBotSettingCreateButton />
          </PageControls>
        }
        filter={<GlobalBotSettingFilter total={total} />}
        addon={<Outlet />}
      >
        <PageTop
          total={total}
          loading={loading}
          {...(isPhone && {
            sorting: [
              { key: 'game_type', title: t('common.game_type'), default: true },
              { key: 'name', title: t('common.name') },
            ],
          })}
          meta={meta}
        />
        {(isDesktop || isTablet) && (
          <Table
            data={data}
            search={search}
            {...getTableProps(query)}
            className={styles.table}
            columns={[
              {
                key: 'name',
                title: t('common.name'),
                sorting: true,
                render: ({ values }) => <GlobalBotSettingDetailLink data={values} />,
              },
              {
                key: 'game_type',
                sorting: true,
                title: <span className={styles.cellHeader}>{t('common.game_type')}</span>,
                render: ({ values }) => TRAINER_GAME_TYPE_LABELS[values.game_type],
              },
              {
                title: t('common.code'),
                render: ({ values }) => <FadingText text={getBotCode(values)} />,
              },
              {
                title: t('common.build'),
                render: ({ values }) => <FadingText text={values.bot_build} />,
              },
              {
                title: t('common.code_tight'),
                render: ({ values }) => <FadingText text={getBotCodeTight(values)} />,
              },
              {
                title: t('common.build_tight'),
                render: ({ values }) => <FadingText text={getBotBuildTight(values)} />,
              },
              {
                title: t('common.workspace'),
                render: ({ values }) => <FadingText text={getWorkspace(values)} />,
              },
              {
                render: ({ values }) => <GlobalBotSettingDropdown data={values} />,
                align: 'right',
                controls: true,
              },
            ]}
            renderRow={(row, index) => (
              <Table.Row
                highlighted={checkIsCreated(row.data.id)}
                active={row.data.id === botSettingId}
                danger={row.data.is_deleted}
                {...checkIsInView(index)}
                {...row}
              />
            )}
          />
        )}
        {isPhone && (
          <Card.Group>
            {data.map((item, index) => (
              <Card
                key={item.id}
                highlighted={checkIsCreated(item.id)}
                danger={item.is_deleted}
                {...checkIsInView(index)}
              >
                <Card.Top>
                  <GlobalBotSettingDetailLink data={item} />
                  <Card.Top.Controls>
                    <GlobalBotSettingDropdown data={item} />
                  </Card.Top.Controls>
                </Card.Top>
                <Card.Field.Group>
                  <Card.Field label={t('common.game_type')}>
                    {TRAINER_GAME_TYPE_LABELS[item.game_type]}
                  </Card.Field>
                  <Card.Field label={t('common.code')}>{getBotCode(item)}</Card.Field>
                  <Card.Field label={t('common.build')}>{item.bot_build}</Card.Field>
                  <Card.Field label={t('common.code_tight')}>{getBotCodeTight(item)}</Card.Field>
                  <Card.Field label={t('common.build_tight')}>{getBotBuildTight(item)}</Card.Field>
                  <Card.Field label={t('common.workspace')}>{getWorkspace(item)}</Card.Field>
                </Card.Field.Group>
              </Card>
            ))}
          </Card.Group>
        )}
      </Page>
    </GlobalBotSettingProvider>
  );
};

export default GlobalBotSettingsPage;
