import { useTranslation } from 'react-i18next';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import PageAddon from '../../components/PageAddon';
import icon from '../../static/icons';
import { formatAbsoluteDate } from '../../utils/date';
import GlobalBotSettingDetailDropdown from './GlobalBotSettingDetailDropdown';
import useForm from './useForm';

interface Props {
  data: BotSettingSchema;
  onClose: () => void;
  deletable?: boolean;
}

const GlobalBotSettingDetail = (props: Props) => {
  const { data, onClose, deletable = true } = props;

  const { id, name, modified_on, created_on, is_deleted } = data;

  const { t } = useTranslation();

  const { fields } = useForm({ data });

  const titleIcons = is_deleted && icon('trash', 12);

  return (
    <PageAddon copy title={name} icons={titleIcons} onClose={onClose}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        {fields.game_type}
        {fields.bot_code}
        {fields.bot_build}
        {fields.bot_build_straddle}
        {fields.bot_code_tight}
        {fields.bot_build_tight}
        {fields.maintenance_id}
        {fields.params_size}
        {fields.params_size_hu}
        {fields.description}
        <PageAddon.Field label={t('common.modified')}>
          <PageAddon.Copy>{formatAbsoluteDate(modified_on)}</PageAddon.Copy>
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          <PageAddon.Copy>{formatAbsoluteDate(created_on)}</PageAddon.Copy>
        </PageAddon.Field>
      </PageAddon.Fields>
      {!is_deleted && (
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            {deletable && <GlobalBotSettingDetailDropdown data={data} />}
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      )}
    </PageAddon>
  );
};

export default GlobalBotSettingDetail;
