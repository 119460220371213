import { Routes } from './types';

const routes: Routes = {
  home: '/',

  registration: '/registration',
  invite: '/invite',
  login: '/login',
  recovery: '/recovery',
  reset: '/reset',

  storybook: '/storybook',

  error404: '/404',

  workspaceUsers: ({ workspaceId }) => `/workspaces/${workspaceId}/members`,
  workspaceUser: ({ workspaceId, userId }) => `/workspaces/${workspaceId}/members/${userId}`,
  workspaceUserInvite: ({ workspaceId }) => `/workspaces/${workspaceId}/members/invite`,

  workspaceAccounts: ({ workspaceId }) => `/workspaces/${workspaceId}/accounts`,
  workspaceAccount: ({ workspaceId, accountId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}`,
  workspaceAccountsCreate: ({ workspaceId }) => `/workspaces/${workspaceId}/accounts/create`,
  workspaceAccountsAutoCreate: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/accounts/auto-create`,
  workspaceAccountsImport: ({ workspaceId }) => `/workspaces/${workspaceId}/accounts/import`,
  workspaceAccountNotes: ({ workspaceId, accountId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/notes`,
  workspaceAccountTransactions: ({ workspaceId, accountId, type, id }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/transactions/${type}${id ? `/${id}` : ''}`,
  workspaceAccountBotSetting: ({ workspaceId, accountId, botSettingId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/bot-setting/${botSettingId}`,
  workspaceAccountChannel: ({ workspaceId, accountId, channelId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/channel/${channelId}`,
  workspaceAccountDeal: ({ workspaceId, accountId, dealId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/deal/${dealId}`,
  workspaceAccountUser: ({ workspaceId, accountId, userId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/user/${userId}`,
  workspaceAccountIdentity: ({ workspaceId, accountId, identityId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/identity/${identityId}`,
  workspaceAccountSchedule: ({ workspaceId, accountId }) =>
    `/workspaces/${workspaceId}/accounts/${accountId}/schedule`,
  workspaceAccountsBulkEdit: ({ workspaceId }) => `/workspaces/${workspaceId}/accounts/bulk-edit`,
  workspaceAccountsBulkStrategyEdit: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/accounts/bulk-edit-strategy`,
  workspaceAccountsBulkRoomConfig: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/accounts/bulk-room-config`,
  workspaceAccountsBulkSuccess: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/accounts/bulk-success`,
  workspaceAccountsBulkError: ({ workspaceId }) => `/workspaces/${workspaceId}/accounts/bulk-error`,
  workspaceAccountsBulkErrorAccount: ({ workspaceId, accountId }) =>
    `/workspaces/${workspaceId}/accounts/bulk-error/account/${accountId}`,

  workspaceSessions: ({ workspaceId }) => `/workspaces/${workspaceId}/accounts/sessions`,
  workspaceSession: ({ workspaceId, accountId, sessionId }) =>
    `/workspaces/${workspaceId}/accounts/sessions/${accountId}/${sessionId}`,
  workspaceSessionEdit: ({ workspaceId, accountId, sessionId }) =>
    `/workspaces/${workspaceId}/accounts/sessions/${accountId}/${sessionId}/edit`,
  workspaceSessionAccount: ({ workspaceId, accountId, sessionId }) =>
    `/workspaces/${workspaceId}/accounts/sessions/${accountId}/${sessionId}/account/${accountId}`,

  workspaceChannels: ({ workspaceId }) => `/workspaces/${workspaceId}/channels`,
  workspaceChannelsCreate: ({ workspaceId }) => `/workspaces/${workspaceId}/channels/create`,
  workspaceChannelsImport: ({ workspaceId }) => `/workspaces/${workspaceId}/channels/import`,
  workspaceChannel: ({ workspaceId, channelId }) =>
    `/workspaces/${workspaceId}/channels/${channelId}`,

  workspaceClubManagers: ({ workspaceId }) => `/workspaces/${workspaceId}/club-managers`,
  workspaceClubManagersCreate: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/club-managers/create`,
  workspaceClubManager: ({ workspaceId, managerId }) =>
    `/workspaces/${workspaceId}/club-managers/${managerId}`,
  workspaceClubManagerEdit: ({ workspaceId, managerId }) =>
    `/workspaces/${workspaceId}/club-managers/${managerId}/edit`,

  workspaceClubMembers: ({ workspaceId }) => `/workspaces/${workspaceId}/club-managers/members`,
  workspaceClubMembersAccept: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/club-managers/members/accept`,
  workspaceClubMember: ({ workspaceId, memberId }) =>
    `/workspaces/${workspaceId}/club-managers/members/${memberId}`,
  workspaceClubMemberDeposit: ({ workspaceId, memberId }) =>
    `/workspaces/${workspaceId}/club-managers/members/${memberId}/deposit`,
  workspaceClubMemberWithdraw: ({ workspaceId, memberId }) =>
    `/workspaces/${workspaceId}/club-managers/members/${memberId}/withdraw`,
  workspaceClubMemberManager: ({ workspaceId, memberId, managerId }) =>
    `/workspaces/${workspaceId}/club-managers/members/${memberId}/manager/${managerId}`,
  workspaceClubMemberManagerEdit: ({ workspaceId, memberId, managerId }) =>
    `/workspaces/${workspaceId}/club-managers/members/${memberId}/manager/${managerId}/edit`,

  workspaceClubWhitelist: ({ workspaceId }) => `/workspaces/${workspaceId}/club-managers/whitelist`,
  workspaceClubWhitelistCreate: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/club-managers/whitelist/create`,
  workspaceClubWhitelistMember: ({ workspaceId, whitelistMemberId }) =>
    `/workspaces/${workspaceId}/club-managers/whitelist/${whitelistMemberId}`,

  workspaceClubTransactions: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/club-managers/transactions`,
  workspaceClubTransaction: ({ workspaceId, transactionId }) =>
    `/workspaces/${workspaceId}/club-managers/transactions/${transactionId}`,
  workspaceClubTransactionMember: ({ workspaceId, transactionId, memberId }) =>
    `/workspaces/${workspaceId}/club-managers/transactions/${transactionId}/member/${memberId}`,
  workspaceClubTransactionMemberDeposit: ({ workspaceId, transactionId, memberId }) =>
    `/workspaces/${workspaceId}/club-managers/transactions/${transactionId}/member/${memberId}/deposit`,
  workspaceClubTransactionMemberWithdraw: ({ workspaceId, transactionId, memberId }) =>
    `/workspaces/${workspaceId}/club-managers/transactions/${transactionId}/member/${memberId}/withdraw`,

  workspaceIdentities: ({ workspaceId }) => `/workspaces/${workspaceId}/identities`,
  workspaceIdentitiesCreate: ({ workspaceId }) => `/workspaces/${workspaceId}/identities/create`,
  workspaceIdentity: ({ workspaceId, identityId }) =>
    `/workspaces/${workspaceId}/identities/${identityId}`,
  workspaceIdentityAccount: ({ workspaceId, identityId, accountId }) =>
    `/workspaces/${workspaceId}/identities/${identityId}/account/${accountId}`,

  workspaceMarketIdentities: ({ workspaceId }) => `/workspaces/${workspaceId}/market/identities`,
  workspaceMarketIdentitiesCart: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/market/identities/cart`,
  workspaceMarketIdentity: ({ workspaceId, identityId }) =>
    `/workspaces/${workspaceId}/market/identities/${identityId}`,
  workspaceMarketIdentityCart: ({ workspaceId, identityId }) =>
    `/workspaces/${workspaceId}/market/identities/${identityId}/cart`,

  workspaceOrders: ({ workspaceId }) => `/workspaces/${workspaceId}/orders`,
  workspaceOrder: ({ workspaceId, orderId }) => `/workspaces/${workspaceId}/orders/${orderId}`,
  workspaceOrderAccount: ({ workspaceId, orderId, accountId }) =>
    `/workspaces/${workspaceId}/orders/${orderId}/account/${accountId}`,

  workspaceDeals: ({ workspaceId }) => `/workspaces/${workspaceId}/deals`,
  workspaceDeal: ({ workspaceId, dealId }) => `/workspaces/${workspaceId}/deals/${dealId}`,
  workspaceDealClub: ({ workspaceId, dealId, clubId }) =>
    `/workspaces/${workspaceId}/deals/${dealId}/club/${clubId}`,
  workspaceDealClubNotes: ({ workspaceId, dealId, clubId }) =>
    `/workspaces/${workspaceId}/deals/${dealId}/club/${clubId}/notes`,
  workspaceDealClubEdit: ({ workspaceId, dealId, clubId }) =>
    `/workspaces/${workspaceId}/deals/${dealId}/club/${clubId}/edit`,

  workspaceSettings: ({ workspaceId }) => `/workspaces/${workspaceId}/settings`,
  workspaceSettingsWallets: ({ workspaceId }) => `/workspaces/${workspaceId}/settings/wallets`,
  workspaceSettingsStrategy: ({ workspaceId }) => `/workspaces/${workspaceId}/settings/strategy`,
  workspaceSettingsTokens: ({ workspaceId }) => `/workspaces/${workspaceId}/settings/tokens`,
  workspaceSettingsTags: ({ workspaceId }) => `/workspaces/${workspaceId}/settings/tags`,
  workspaceSettingsRules: ({ workspaceId }) =>
    `/workspaces/${workspaceId}/settings/seat-control-rules`,

  globalWorkspaces: '/global-workspaces',
  globalWorkspacesCreate: '/global-workspaces/create',
  globalWorkspace: ({ workspaceId }) => `/global-workspaces/${workspaceId}`,
  globalWorkspaceUser: ({ workspaceId, userId }) =>
    `/global-workspaces/${workspaceId}/user/${userId}`,
  globalWorkspaceClub: ({ workspaceId, clubId }) =>
    `/global-workspaces/${workspaceId}/club/${clubId}`,
  globalWorkspaceClubEdit: ({ workspaceId, clubId }) =>
    `/global-workspaces/${workspaceId}/club/${clubId}/edit`,

  globalOrders: '/orders',
  globalOrder: ({ orderId }) => `/orders/${orderId}`,
  globalOrderAccount: ({ orderId, accountId }) => `/orders/${orderId}/account/${accountId}`,

  globalDeals: '/deals',
  globalDealsCreate: '/deals/create',
  globalDeal: ({ dealId }) => `/deals/${dealId}`,
  globalDealNotes: ({ dealId }) => `/deals/${dealId}/notes`,
  globalDealAgent: ({ dealId, agentId }) => `/deals/${dealId}/agent/${agentId}`,
  globalDealAgentNotes: ({ dealId, agentId }) => `/deals/${dealId}/agent/${agentId}/notes`,
  globalDealClub: ({ dealId, clubId }) => `/deals/${dealId}/club/${clubId}`,
  globalDealClubNotes: ({ dealId, clubId }) => `/deals/${dealId}/club/${clubId}/notes`,
  globalDealClubEdit: ({ dealId, clubId }) => `/deals/${dealId}/club/${clubId}/edit`,

  globalAgents: '/deals/agents',
  globalAgentsCreate: '/deals/agents/create',
  globalAgent: ({ agentId }) => `/deals/agents/${agentId}`,
  globalAgentNotes: ({ agentId }) => `/deals/agents/${agentId}/notes`,
  globalAgentRelatedAgent: ({ agentId, relatedAgentId }) =>
    `/deals/agents/${agentId}/related-agent/${relatedAgentId}`,
  globalAgentRelatedAgentNotes: ({ agentId, relatedAgentId }) =>
    `/deals/agents/${agentId}/related-agent/${relatedAgentId}/notes`,

  globalBonuses: '/deals/bonuses',
  globalBonusesCreate: '/deals/bonuses/create',
  globalBonus: ({ bonusId }) => `/deals/bonuses/${bonusId}`,
  globalBonusDeal: ({ bonusId, dealId }) => `/deals/bonuses/${bonusId}/deal/${dealId}`,

  globalExpenses: '/deals/expenses',
  globalExpensesCreate: '/deals/expenses/create',
  globalExpense: ({ expenseId }) => `/deals/expenses/${expenseId}`,
  globalExpenseDeal: ({ expenseId, dealId }) => `/deals/expenses/${expenseId}/deal/${dealId}`,

  globalSeatConrolRules: '/seat-control-rules',
  globalSeatConrolRule: ({ seatControlRuleId }) => `/seat-control-rules/${seatControlRuleId}`,
  globalSeatConrolRuleEdit: ({ seatControlRuleId }) =>
    `/seat-control-rules/${seatControlRuleId}/edit`,

  globalUsers: '/members',
  globalUsersInvite: '/members/invite',
  globalUser: ({ userId }) => `/members/${userId}`,
  globalUserRole: ({ userId, roleId }) => `/members/${userId}/role/${roleId}`,
  globalUserWorkspace: ({ userId, workspaceId }) => `/members/${userId}/workspace/${workspaceId}`,

  globalRoles: '/members/roles',
  globalRolesCreate: '/members/roles/create',
  globalRole: ({ roleId }) => `/members/roles/${roleId}`,

  globalAccounts: '/accounts',
  globalAccount: ({ accountId }) => `/accounts/${accountId}`,
  globalAccountTransactions: ({ accountId, type, id }) =>
    `/accounts/${accountId}/transactions/${type}${id ? `/${id}` : ''}`,
  globalAccountUser: ({ accountId, userId }) => `/accounts/${accountId}/user/${userId}`,
  globalAccountSchedule: ({ accountId }) => `/accounts/${accountId}/schedule`,

  globalSessions: `/accounts/sessions`,
  globalSession: ({ accountId, sessionId }) => `/accounts/sessions/${accountId}/${sessionId}`,
  globalSessionEdit: ({ accountId, sessionId }) =>
    `/accounts/sessions/${accountId}/${sessionId}/edit`,
  globalSessionAccount: ({ accountId, sessionId }) =>
    `/accounts/sessions/${accountId}/${sessionId}/account/${accountId}`,

  globalLobby: '/lobby',
  globalLobbyTable: ({ tableId }) => `/lobby/${tableId}`,
  globalLobbyTableAccount: ({ tableId, accountId }) => `/lobby/${tableId}/account/${accountId}`,

  globalBotSettings: '/bot-settings',
  globalBotSettingsCreate: '/bot-settings/create',
  globalBotSetting: ({ botSettingId }) => `/bot-settings/${botSettingId}`,
  globalBotSettingError: ({ botSettingId }) => `/bot-settings/${botSettingId}/error`,
  globalBotSettingErrorAccount: ({ botSettingId, accountId }) =>
    `/bot-settings/${botSettingId}/error/account/${accountId}`,
  globalBotSettingErrorBotSettingRule: ({ botSettingId, botSettingRuleId }) =>
    `/bot-settings/${botSettingId}/error/bot-setting-rule/${botSettingRuleId}`,

  globalBotSettingsRules: '/bot-settings-rules',
  globalBotSettingsRulesCreate: '/bot-settings-rules/create',
  globalBotSettingRule: ({ botSettingRuleId }) => `/bot-settings-rules/${botSettingRuleId}`,
  globalBotSettingRuleBotSetting: ({ botSettingRuleId, botSettingId }) =>
    `/bot-settings-rules/${botSettingRuleId}/bot-setting/${botSettingId}`,

  globalClubs: '/clubs',
  globalClubsCreate: '/clubs/create',
  globalClub: ({ clubId }) => `/clubs/${clubId}`,
  globalClubEdit: ({ clubId }) => `/clubs/${clubId}/edit`,
  globalClubNotes: ({ clubId }) => `/clubs/${clubId}/notes`,
  globalClubLeague: ({ clubId, leagueId }) => `/clubs/${clubId}/league/${leagueId}`,
  globalClubLeagueEdit: ({ clubId, leagueId }) => `/clubs/${clubId}/league/${leagueId}/edit`,
  globalClubLeagueSuperLeague: ({ clubId, leagueId, superLeagueId }) =>
    `/clubs/${clubId}/league/${leagueId}/super-league/${superLeagueId}`,
  globalClubLeagueSuperLeagueEdit: ({ clubId, leagueId, superLeagueId }) =>
    `/clubs/${clubId}/league/${leagueId}/super-league/${superLeagueId}/edit`,
  globalClubWorkspace: ({ clubId, workspaceId }) => `/clubs/${clubId}/workspace/${workspaceId}`,
  globalClubError: ({ clubId }) => `/clubs/${clubId}/error`,
  globalClubErrorAccount: ({ clubId, accountId }) => `/clubs/${clubId}/error/account/${accountId}`,

  globalLeagues: '/clubs/leagues',
  globalLeaguesCreate: '/clubs/leagues/create',
  globalLeague: ({ leagueId }) => `/clubs/leagues/${leagueId}`,
  globalLeagueEdit: ({ leagueId }) => `/clubs/leagues/${leagueId}/edit`,
  globalLeagueSuperLeague: ({ leagueId, superLeagueId }) =>
    `/clubs/leagues/${leagueId}/super-league/${superLeagueId}`,
  globalLeagueSuperLeagueEdit: ({ leagueId, superLeagueId }) =>
    `/clubs/leagues/${leagueId}/super-league/${superLeagueId}/edit`,

  globalSuperLeagues: '/clubs/super-leagues',
  globalSuperLeaguesCreate: '/clubs/super-leagues/create',
  globalSuperLeague: ({ superLeagueId }) => `/clubs/super-leagues/${superLeagueId}`,
  globalSuperLeagueEdit: ({ superLeagueId }) => `/clubs/super-leagues/${superLeagueId}/edit`,

  globalAreas: '/areas',
  globalAreasCreate: '/areas/create',
  globalArea: ({ areaId }) => `/areas/${areaId}`,
  globalAreaEditAreaRates: ({ areaId }) => `/areas/${areaId}/edit/area-rates`,
};

export default routes;
