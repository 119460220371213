import { RouteObject } from 'react-router-dom';
import routes from '../../config/routes';
import GlobalBotSettingDetail from '../../features/global-bot-setting/GlobalBotSettingDetail';
import GlobalBotSettingLoader from '../../features/global-bot-setting/GlobalBotSettingLoader';
import GlobalBotSettingRuleDetail from '../../features/global-bot-setting-rule/GlobalBotSettingRuleDetail';
import GlobalBotSettingRuleForm from '../../features/global-bot-setting-rule/GlobalBotSettingRuleForm';
import GlobalBotSettingRuleLoader from '../../features/global-bot-setting-rule/GlobalBotSettingRuleLoader';
import GlobalBotSettingsRulesPage from '../../pages/GlobalBotSettingsRulesPage';
import Route from './Route';

const page = <GlobalBotSettingsRulesPage />;

const createForm = (
  <Route>
    {({ navigate }) => (
      <GlobalBotSettingRuleForm
        onClose={() => navigate(routes.globalBotSettingsRules)}
        onCreate={({ botSettingRuleId }) =>
          navigate(routes.globalBotSettingRule({ botSettingRuleId }))
        }
      />
    )}
  </Route>
);

const detail = (
  <Route params={['botSettingRuleId']}>
    {({ botSettingRuleId, navigate }) => (
      <GlobalBotSettingRuleLoader botSettingRuleId={botSettingRuleId}>
        {({ data }) => (
          <GlobalBotSettingRuleDetail
            data={data}
            onClose={() => navigate(routes.globalBotSettingsRules)}
            getBotSettingRoute={({ botSettingId }) =>
              routes.globalBotSettingRuleBotSetting({ botSettingRuleId, botSettingId })
            }
          />
        )}
      </GlobalBotSettingRuleLoader>
    )}
  </Route>
);

const detailBotSetting = (
  <Route params={['botSettingId', 'botSettingRuleId']}>
    {({ botSettingId, botSettingRuleId, navigate }) => (
      <GlobalBotSettingLoader botSettingId={botSettingId}>
        {({ data }) => (
          <GlobalBotSettingDetail
            deletable={false}
            data={data}
            onClose={() => navigate(routes.globalBotSettingRule({ botSettingRuleId }))}
          />
        )}
      </GlobalBotSettingLoader>
    )}
  </Route>
);

const globalBotSettingsRulesPage: RouteObject = {
  path: routes.globalBotSettingsRules,
  element: page,
  children: [
    {
      path: routes.globalBotSettingsRulesCreate,
      element: createForm,
    },
    {
      path: routes.globalBotSettingRule({ botSettingRuleId: ':botSettingRuleId' }),
      element: detail,
      children: [
        {
          path: routes.globalBotSettingRuleBotSetting({
            botSettingRuleId: ':botSettingRuleId',
            botSettingId: ':botSettingId',
          }),
          element: detailBotSetting,
        },
      ],
    },
  ],
};

export default globalBotSettingsRulesPage;
