import { updateGlobalBotSetting } from '../../api';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import ChangeNameDialog from '../../components/ChangeNameDialog';

interface Fields {
  name: string;
}

interface Props {
  data: BotSettingSchema;
  onClose: () => void;
}

const GlobalBotSettingChangeNameDialog = (props: Props) => {
  const { data, onClose } = props;

  return (
    <ChangeNameDialog
      name={data.name}
      onClose={onClose}
      onSubmit={(values: Fields) =>
        updateGlobalBotSetting({ botSettingId: data.id, payload: { name: values.name } })
      }
    />
  );
};

export default GlobalBotSettingChangeNameDialog;
