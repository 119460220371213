import { useState } from 'react';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import Dropdown from '../../components/Dropdown';
import GlobalBotSettingChangeNameDialog from './GlobalBotSettingChangeNameDialog';
import useDropdown from './useDropdown';

interface Props {
  data: BotSettingSchema;
}

const GlobalBotSettingDetailDropdown = (props: Props) => {
  const { data } = props;

  const [open, setOpen] = useState(false);

  const { title, disabled, options } = useDropdown({
    data,
    changeName: () => setOpen(true),
  });

  return (
    <>
      {open && <GlobalBotSettingChangeNameDialog data={data} onClose={() => setOpen(false)} />}
      <Dropdown
        title={title}
        buttonSize="medium"
        disabled={disabled}
        options={[options.changeName, options.divider, options.delete]}
      />
    </>
  );
};

export default GlobalBotSettingDetailDropdown;
