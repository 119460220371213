import { useTranslation } from 'react-i18next';
import {
  createGlobalWorkspace,
  loadGlobalUsers,
  loadGlobalWorkspaceStages,
  updateGlobalWorkspace,
} from '../../api';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import { BUSINESS_MODEL, WorkspaceSchema, WorkspaceType } from '../../api/schemas/workspaceSchema';
import Checkbox from '../../components/Checkbox';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Select, { Option, Options } from '../../components/Select';
import useQuickForm from '../../hooks/useQuickForm';
import { secondsToHours, hoursToSeconds } from '../../utils/data';
import { maxLength, minLength, required, time, uuid } from '../../utils/form';
import notify from '../../utils/notify';
import { can } from '../../utils/permissions';
import {
  TRAINER_POKER_ROOM_LABELS,
  TRAINER_TECH_LABELS,
  trainerPokerRoomOptions,
  trainerTechOptions,
} from '../../utils/trainer';
import { businessModelOptions, WORKSPACE_TYPES_LABELS, workspaceTypesOptions } from './helpers';

interface Fields {
  user_id: Option<GlobalUserSchema['id']>;
  name: string;
  business_model: Option<WorkspaceSchema['business_model']>;
  maintenance_types: Options<WorkspaceType>;
  bar_seq: string;
  trainer_tech: Options<WorkspaceSchema['trainer_tech'][number]>;
  poker_rooms: Options<WorkspaceSchema['poker_rooms'][number]>;
  start_new_table: boolean;
  external_partner: boolean;
  auto_create_mining_session: boolean;
  mining_stage_ids: Options<WorkspaceSchema['mining_stages'][number]['id']>;
  mining_session_duration: string;
  mining_session_interval: string;
  hands_billing: boolean;
  allow_negative_balance_pilot: boolean;
}

interface Args {
  data?: WorkspaceSchema;
  onCreate?: (args: { workspaceId: WorkspaceSchema['id'] }) => void;
}

const useForm = (args: Args) => {
  const { data, onCreate } = args;

  const { t } = useTranslation();

  const form = useQuickForm<Fields>({
    data,
    defaultValues: {
      user_id: null,
      name: '',
      business_model: businessModelOptions.find(
        (item) => item.value === (data ? data.business_model : BUSINESS_MODEL.BOT)
      ),
      maintenance_types: data
        ? data.maintenance_types.map((item) => ({
            value: item,
            label: WORKSPACE_TYPES_LABELS[item],
          }))
        : [],
      bar_seq: data?.bar_seq || '',
      trainer_tech: data
        ? data.trainer_tech.map((item) => ({
            value: item,
            label: TRAINER_TECH_LABELS[item],
          }))
        : [],
      poker_rooms: data
        ? data.poker_rooms.map((item) => ({
            value: item,
            label: TRAINER_POKER_ROOM_LABELS[item],
          }))
        : [],
      start_new_table: data?.start_new_table || false,
      external_partner: data?.external_partner || false,
      auto_create_mining_session: data?.auto_create_mining_session || false,
      mining_stage_ids: data
        ? data.mining_stages.map((item) => ({ value: item.id, label: item.name }))
        : [],
      mining_session_duration: data ? secondsToHours(data.mining_session_duration) : '',
      mining_session_interval: data ? secondsToHours(data.mining_session_interval) : '',
      hands_billing: data?.hands_billing || false,
      allow_negative_balance_pilot: !data?.allow_negative_balance_pilot || false,
    },
  });

  const { control, watch, handleSubmit } = form;

  const onSubmit = async (values: Fields) => {
    if (!values.business_model) throw new Error();

    const commonPayload = {
      business_model: values.business_model.value,
      maintenance_types: values.maintenance_types.map((item) => item.value),
    };

    if (data) {
      await updateGlobalWorkspace({
        workspaceId: data.id,
        payload: {
          ...commonPayload,
          bar_seq: values.bar_seq || null,
          trainer_tech: values.trainer_tech.map((item) => item.value),
          poker_rooms: values.poker_rooms.map((item) => item.value),
          start_new_table: values.start_new_table,
          external_partner: values.external_partner,
          auto_create_mining_session: values.auto_create_mining_session,
          mining_stage_ids: values.mining_stage_ids.map((item) => item.value),
          mining_session_duration: hoursToSeconds(values.mining_session_duration),
          mining_session_interval: hoursToSeconds(values.mining_session_interval),
          hands_billing: values.hands_billing,
          allow_negative_balance_pilot: !values.allow_negative_balance_pilot,
        },
      });
    } else {
      if (!values.user_id) throw new Error();

      const response = await createGlobalWorkspace({
        payload: { ...commonPayload, user_id: values.user_id.value, name: values.name },
      });

      if (onCreate) onCreate({ workspaceId: response.id });

      notify('success', { title: t('sentences.record_has_been_created') });
    }
  };

  const autoMining = watch('auto_create_mining_session');

  const disabled = !can.workspace.createUpdate;

  const detail = data && !onCreate;

  const commonProps = { control, disabled, ...(detail && { onSubmit: handleSubmit(onSubmit) }) };

  return {
    form,
    onSubmit,
    fields: {
      name: (
        <PageAddon.Field label={t('common.name')}>
          <Input.Quick
            name="name"
            rules={{ validate: { required, minLength: minLength(), maxLength: maxLength() } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      user_id: (
        <PageAddon.Field label={t('common.owner')}>
          <Select.Async
            onLoad={async (params) => {
              const response = await loadGlobalUsers(params);

              const options = response.items.map((item) => ({
                value: item.id,
                label: item.username,
              }));

              return options;
            }}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                name="user_id"
                rules={{ validate: { required } }}
                {...commonProps}
                {...selectAsyncProps}
              />
            )}
          </Select.Async>
        </PageAddon.Field>
      ),
      business_model: (
        <PageAddon.Field label={t('common.business_model')}>
          <Select.Quick name="business_model" options={businessModelOptions} {...commonProps} />
        </PageAddon.Field>
      ),
      maintenance_types: (
        <PageAddon.Field label={t('common.types')}>
          <Select.Multi.Quick
            name="maintenance_types"
            options={workspaceTypesOptions}
            rules={{ validate: { required } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      bar_seq: (
        <PageAddon.Field label={t('common.bar_seq')}>
          <Input.Quick name="bar_seq" rules={{ validate: { uuid } }} {...commonProps} />
        </PageAddon.Field>
      ),
      trainer_tech: (
        <PageAddon.Field label={t('common.trainer_tech')}>
          <Select.Multi.Quick name="trainer_tech" options={trainerTechOptions} {...commonProps} />
        </PageAddon.Field>
      ),
      poker_rooms: (
        <PageAddon.Field label={t('common.poker_rooms')}>
          <Select.Multi.Quick
            name="poker_rooms"
            options={trainerPokerRoomOptions}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      start_new_table: (
        <PageAddon.Field label={t('common.start_new_table')}>
          <Checkbox.Quick name="start_new_table" {...commonProps} />
        </PageAddon.Field>
      ),
      external_partner: (
        <PageAddon.Field label={t('common.external_partner')}>
          <Checkbox.Quick name="external_partner" {...commonProps} />
        </PageAddon.Field>
      ),
      auto_create_mining_session: (
        <PageAddon.Field label={t('common.automining')}>
          <Checkbox.Quick name="auto_create_mining_session" {...commonProps} />
        </PageAddon.Field>
      ),
      mining_stage_ids: (
        <PageAddon.Field label={t('common.stages')} tooltip={t('sentences.automining_stages')}>
          <Select.AsyncV2
            onLoad={(params) => {
              if (!data) throw new Error();

              return loadGlobalWorkspaceStages({ workspaceId: data.id }, params);
            }}
            select={(item) => ({
              value: item.id,
              label: item.name,
            })}
          >
            {({ options, ...selectAsyncProps }) => (
              <Select.Multi.Quick
                name="mining_stage_ids"
                options={options}
                {...selectAsyncProps}
                {...{ ...commonProps, disabled: commonProps.disabled || !autoMining }}
              />
            )}
          </Select.AsyncV2>
        </PageAddon.Field>
      ),
      mining_session_duration: (
        <PageAddon.Field
          label={t('common.session_duration')}
          tooltip={t('sentences.automining_session_duration')}
        >
          <Input.Quick
            name="mining_session_duration"
            rules={{ validate: { required, time } }}
            patternFormat="time"
            {...{ ...commonProps, disabled: commonProps.disabled || !autoMining }}
          />
        </PageAddon.Field>
      ),
      mining_session_interval: (
        <PageAddon.Field
          label={t('common.session_interval')}
          tooltip={t('sentences.automining_session_interval')}
        >
          <Input.Quick
            name="mining_session_interval"
            rules={{ validate: { required, time } }}
            patternFormat="time"
            {...{ ...commonProps, disabled: commonProps.disabled || !autoMining }}
          />
        </PageAddon.Field>
      ),
      hands_billing: (
        <PageAddon.Field label={t('common.hands_billing')}>
          <Checkbox.Quick name="hands_billing" {...commonProps} />
        </PageAddon.Field>
      ),
      allow_negative_balance_pilot: (
        <PageAddon.Field label={t('common.balance_check')}>
          <Checkbox.Quick name="allow_negative_balance_pilot" {...commonProps} />
        </PageAddon.Field>
      ),
    },
  };
};

export default useForm;
