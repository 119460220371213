import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { loadGlobalExpensesFilterValues } from '../../api';
import Filter from '../../components/Filter';
import queryKeys from '../../config/queryKeys';
import useFilter from '../../hooks/useFilter';
import useURL from '../../hooks/useURL';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import { TYPE_LABELS } from './helpers';

interface Fields {
  date_ge: string;
  date_le: string;
  deal_id_in: string[];
  type_in: string[];
  poker_room_in: string[];
  club_id_in: string[];
  agent_id_in: string[];
  holder_id_in: string[];
  updated_ge: string;
  updated_le: string;
  created_ge: string;
  created_le: string;
  show_deleted: boolean;
}

const GlobalExpenseFilter = () => {
  const { t } = useTranslation();
  const { urlParams } = useURL();

  const queryKey = [queryKeys.globalExpensesFilterValues, urlParams.show_deleted];
  const queryFn = () => loadGlobalExpensesFilterValues({ show_deleted: urlParams.show_deleted });

  const { data, isPending } = useQuery({
    queryKey,
    queryFn,
    placeholderData: keepPreviousData,
    meta: { ignoredStatuses: [403] },
  });

  const filter = useFilter<Fields>({
    date_ge: 'single',
    date_le: 'single',
    deal_id_in: 'multi',
    type_in: 'multi',
    poker_room_in: 'multi',
    club_id_in: 'multi',
    agent_id_in: 'multi',
    holder_id_in: 'multi',
    updated_ge: 'single',
    updated_le: 'single',
    created_ge: 'single',
    created_le: 'single',
    show_deleted: 'toggle',
  });

  const { values, setValue, clearValue } = filter;

  const {
    date_ge,
    date_le,
    deal_id_in,
    type_in,
    poker_room_in,
    club_id_in,
    agent_id_in,
    holder_id_in,
    updated_ge,
    updated_le,
    created_ge,
    created_le,
    show_deleted,
  } = values;

  const filterProps = { filter };

  if (isPending) {
    return (
      <Filter {...filterProps}>
        <Filter.Placeholder />
      </Filter>
    );
  }

  if (!data) return null;

  const { deal, type, poker_room, club, agent, holder } = data;

  return (
    <Filter {...filterProps}>
      <div>
        <Filter.Collapse
          label={t('common.date')}
          {...((date_ge || date_le) && {
            onClear: clearValue(['date_ge', 'date_le']),
          })}
        >
          <Filter.Date
            from={{ value: date_ge, onChange: setValue('date_ge') }}
            to={{ value: date_le, onChange: setValue('date_le') }}
          />
        </Filter.Collapse>
        {deal.length !== 0 && (
          <Filter.Collapse
            label={t('common.deal')}
            count={deal_id_in.length}
            onClear={clearValue('deal_id_in')}
          >
            <Filter.MultiCheckbox
              config={deal.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('deal_id_in'),
                checked: deal_id_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {type.length !== 0 && (
          <Filter.Collapse
            label={t('common.type')}
            count={type_in.length}
            onClear={clearValue('type_in')}
          >
            <Filter.MultiCheckbox
              config={type.map((item) => ({
                value: String(item),
                label: TYPE_LABELS[item],
                onChange: setValue('type_in'),
                checked: type_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {poker_room.length !== 0 && (
          <Filter.Collapse
            label={t('common.poker_room')}
            count={poker_room_in.length}
            onClear={clearValue('poker_room_in')}
          >
            <Filter.MultiCheckbox
              config={data.poker_room.map((item) => ({
                value: String(item),
                label: TRAINER_POKER_ROOM_LABELS[item],
                onChange: setValue('poker_room_in'),
                checked: poker_room_in.includes(String(item)),
              }))}
            />
          </Filter.Collapse>
        )}
        {club.length !== 0 && (
          <Filter.Collapse
            label={t('common.club')}
            count={club_id_in.length}
            onClear={clearValue('club_id_in')}
          >
            <Filter.MultiCheckbox
              config={club.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('club_id_in'),
                checked: club_id_in.includes(item.id),
                addon: item.code,
              }))}
            />
          </Filter.Collapse>
        )}
        {agent.length !== 0 && (
          <Filter.Collapse
            label={t('common.agent')}
            count={agent_id_in.length}
            onClear={clearValue('agent_id_in')}
          >
            <Filter.MultiCheckbox
              config={agent.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('agent_id_in'),
                checked: agent_id_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        {holder.length !== 0 && (
          <Filter.Collapse
            label={t('common.holder')}
            count={holder_id_in.length}
            onClear={clearValue('holder_id_in')}
          >
            <Filter.MultiCheckbox
              config={holder.map((item) => ({
                value: item.id,
                label: item.name,
                onChange: setValue('holder_id_in'),
                checked: holder_id_in.includes(item.id),
              }))}
            />
          </Filter.Collapse>
        )}
        <Filter.Collapse
          label={t('common.modified')}
          {...((updated_ge || updated_le) && {
            onClear: clearValue(['updated_ge', 'updated_le']),
          })}
        >
          <Filter.Date
            from={{ value: updated_ge, onChange: setValue('updated_ge') }}
            to={{ value: updated_le, onChange: setValue('updated_le') }}
          />
        </Filter.Collapse>
        <Filter.Collapse
          label={t('common.created')}
          {...((created_ge || created_le) && {
            onClear: clearValue(['created_ge', 'created_le']),
          })}
        >
          <Filter.Date
            from={{ value: created_ge, onChange: setValue('created_ge') }}
            to={{ value: created_le, onChange: setValue('created_le') }}
          />
        </Filter.Collapse>
      </div>
      <Filter.Switcher checked={show_deleted} onChange={setValue('show_deleted')}>
        {t('common.show_deleted')}
      </Filter.Switcher>
    </Filter>
  );
};

export default GlobalExpenseFilter;
