import { useTranslation } from 'react-i18next';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import Form from '../../components/Form';
import PageAddon from '../../components/PageAddon';
import SubmitButton from '../../components/SubmitButton';
import useForm from './useForm';

interface Props {
  onClose: () => void;
  onCreate: (args: { botSettingId: BotSettingSchema['id'] }) => void;
}

const GlobalBotSettingForm = (props: Props) => {
  const { onClose, onCreate } = props;

  const { t } = useTranslation();

  const { form, fields, onSubmit } = useForm({ onCreate });

  return (
    <PageAddon title={t('common.create_bot_settings')} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        {fields.name}
        {fields.game_type}
        {fields.bot_code}
        {fields.bot_build}
        {fields.bot_build_straddle}
        {fields.bot_code_tight}
        {fields.bot_build_tight}
        {fields.maintenance_id}
        {fields.params_size}
        {fields.params_size_hu}
        {fields.description}
        <PageAddon.Controls>
          <SubmitButton>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalBotSettingForm;
