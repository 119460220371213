import { useTranslation } from 'react-i18next';
import { createWorkspaceChannel, updateWorkspaceChannel } from '../../api';
import { ChannelSchema } from '../../api/schemas/channelSchema';
import { WorkspaceSchema } from '../../api/schemas/workspaceSchema';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import useQuickForm from '../../hooks/useQuickForm';
import { max, maxLength, minLength, required } from '../../utils/form';
import notify from '../../utils/notify';
import { numbers } from '../../utils/validations';

interface Fields {
  host: string;
  port: string;
  username: string;
  password: string;
}

interface Args {
  workspaceId: WorkspaceSchema['id'];
  data?: ChannelSchema;
  onCreate?: (args: { channelId: ChannelSchema['id']; host: ChannelSchema['host'] }) => void;
}

const useForm = (args: Args) => {
  const { workspaceId, data, onCreate } = args;

  const { t } = useTranslation();

  const form = useQuickForm<Fields>({
    data,
    defaultValues: {
      host: data?.host || '',
      port: data ? String(data.port) : '',
      username: data?.username || '',
      password: data?.password || '',
    },
  });

  const { control, handleSubmit } = form;

  const onSubmit = async (values: Fields) => {
    const payload = {
      ...values,
      port: Number(values.port),
    };

    if (data) {
      await updateWorkspaceChannel({ workspaceId, channelId: data.id, payload });
    } else {
      const response = await createWorkspaceChannel({ workspaceId, payload });

      if (onCreate) onCreate({ channelId: response.id, host: values.host });

      notify('success', { title: t('sentences.record_has_been_created') });
    }
  };

  const detail = data && !onCreate;

  const commonProps = { control, ...(detail && { onSubmit: handleSubmit(onSubmit) }) };

  return {
    form,
    onSubmit,
    fields: {
      host: (
        <PageAddon.Field label={t('common.host')}>
          <Input.Quick name="host" rules={{ validate: { required } }} {...commonProps} />
        </PageAddon.Field>
      ),
      port: (
        <PageAddon.Field label={t('common.port')}>
          <Input.Quick
            name="port"
            allow={numbers}
            rules={{ validate: { required, max: max(t('common.port'), 65535) } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      username: (
        <PageAddon.Field label={t('common.username')}>
          <Input.Quick
            name="username"
            rules={{ validate: { required, minLength: minLength(4), maxLength: maxLength() } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
      password: (
        <PageAddon.Field label={t('common.password')}>
          <Input.Quick
            name="password"
            type="password"
            rules={{ validate: { required, minLength: minLength(4) } }}
            {...commonProps}
          />
        </PageAddon.Field>
      ),
    },
  };
};

export default useForm;
