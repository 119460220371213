import { updateGlobalBotSettingRule } from '../../api';
import { BotSettingRuleSchema } from '../../api/schemas/botSettingRuleSchema';
import ChangeNameDialog from '../../components/ChangeNameDialog';

interface Fields {
  name: string;
}

interface Props {
  data: BotSettingRuleSchema;
  onClose: () => void;
}

const GlobalBotSettingRuleChangeNameDialog = (props: Props) => {
  const { data, onClose } = props;

  return (
    <ChangeNameDialog
      name={data.name}
      onClose={onClose}
      onSubmit={(values: Fields) =>
        updateGlobalBotSettingRule({ botSettingRuleId: data.id, payload: { name: values.name } })
      }
    />
  );
};

export default GlobalBotSettingRuleChangeNameDialog;
