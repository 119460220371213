import { updateGlobalRole } from '../../api';
import { GlobalRoleSchema } from '../../api/schemas/globalRoleSchema';
import ChangeNameDialog from '../../components/ChangeNameDialog';

interface Fields {
  name: string;
}

interface Props {
  data: GlobalRoleSchema;
  onClose: () => void;
}

const GlobalRoleChangeNameDialog = (props: Props) => {
  const { data, onClose } = props;

  return (
    <ChangeNameDialog
      name={data.name}
      onClose={onClose}
      onSubmit={(values: Fields) =>
        updateGlobalRole({ roleId: data.id, payload: { name: values.name } })
      }
    />
  );
};

export default GlobalRoleChangeNameDialog;
