import { useTranslation } from 'react-i18next';
import { createGlobalBotSetting, loadGlobalWorkspaces, updateGlobalBotSetting } from '../../api';
import { BotSettingSchema } from '../../api/schemas/botSettingSchema';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Select, { Option } from '../../components/Select';
import Textarea from '../../components/Textarea';
import useQuickForm from '../../hooks/useQuickForm';
import { required } from '../../utils/form';
import notify from '../../utils/notify';
import { can } from '../../utils/permissions';
import { TRAINER_GAME_TYPE_LABELS, trainerGameTypeOptions } from '../../utils/trainer';

interface Fields {
  name: string;
  game_type: Option<BotSettingSchema['game_type']>;
  bot_code: string;
  bot_build: string;
  bot_build_straddle: string;
  bot_code_tight: string;
  bot_build_tight: string;
  maintenance_id: Option<Exclude<BotSettingSchema['maintenance'], null>['id']>;
  params_size: string;
  params_size_hu: string;
  description: string;
}

interface Args {
  data?: BotSettingSchema;
  onCreate?: (args: { botSettingId: BotSettingSchema['id'] }) => void;
}

const useForm = (args: Args) => {
  const { data, onCreate } = args;

  const { t } = useTranslation();

  const form = useQuickForm<Fields>({
    data,
    defaultValues: {
      name: data?.name || '',
      game_type: data
        ? { value: data.game_type, label: TRAINER_GAME_TYPE_LABELS[data.game_type] }
        : null,
      bot_code: data?.bot_code || '',
      bot_build: data?.bot_build || '',
      bot_build_straddle: data?.bot_build_straddle || '',
      bot_code_tight: data?.bot_code_tight || '',
      bot_build_tight: data?.bot_build_tight || '',
      maintenance_id: data?.maintenance
        ? { label: data.maintenance.name, value: data.maintenance.id }
        : null,
      params_size: data?.params_size || '',
      params_size_hu: data?.params_size_hu || '',
      description: data?.description || '',
    },
  });

  const { control, handleSubmit } = form;

  const onSubmit = async (values: Fields) => {
    const commonPayload = {
      name: values.name,
      bot_code: values.bot_code || null,
      bot_build: values.bot_build,
      bot_build_straddle: values.bot_build_straddle || null,
      bot_code_tight: values.bot_code_tight || null,
      bot_build_tight: values.bot_build_tight || null,
      params_size: values.params_size || null,
      params_size_hu: values.params_size_hu || null,
      description: values.description || null,
    };

    if (data) {
      await updateGlobalBotSetting({ botSettingId: data.id, payload: commonPayload });
    } else {
      if (!values.game_type) throw new Error();

      const response = await createGlobalBotSetting({
        payload: {
          ...commonPayload,
          game_type: values.game_type.value,
          maintenance_id: values.maintenance_id?.value || null,
        },
      });

      if (onCreate) onCreate({ botSettingId: response.id });

      notify('success', { title: t('sentences.record_has_been_created') });
    }
  };

  const detail = data && !onCreate;

  const disabled = !can.botSetting.createUpdate;

  const commonProps = { control, disabled, ...(detail && { onSubmit: handleSubmit(onSubmit) }) };

  return {
    form,
    onSubmit,
    fields: {
      name: (
        <PageAddon.Field label={t('common.name')}>
          <Input.Quick name="name" rules={{ validate: { required } }} {...commonProps} />
        </PageAddon.Field>
      ),
      game_type: (
        <PageAddon.Field label={t('common.game_type')}>
          <Select.Quick
            name="game_type"
            options={trainerGameTypeOptions}
            rules={{ validate: { required } }}
            {...commonProps}
            {...{ ...commonProps, disabled: commonProps.disabled || detail }}
          />
        </PageAddon.Field>
      ),
      bot_code: (
        <PageAddon.Field label={t('common.code')}>
          <Input.Quick name="bot_code" {...commonProps} />
        </PageAddon.Field>
      ),
      bot_build: (
        <PageAddon.Field label={t('common.build')}>
          <Input.Quick name="bot_build" rules={{ validate: { required } }} {...commonProps} />
        </PageAddon.Field>
      ),
      bot_build_straddle: (
        <PageAddon.Field label={t('common.straddle')}>
          <Input.Quick name="bot_build_straddle" {...commonProps} />
        </PageAddon.Field>
      ),
      bot_code_tight: (
        <PageAddon.Field label={t('common.code_tight')}>
          <Input.Quick name="bot_code_tight" {...commonProps} />
        </PageAddon.Field>
      ),
      bot_build_tight: (
        <PageAddon.Field label={t('common.build_tight')}>
          <Input.Quick name="bot_build_tight" {...commonProps} />
        </PageAddon.Field>
      ),
      maintenance_id: (
        <PageAddon.Field label={t('common.workspace')}>
          <Select.Async
            onLoad={async (params) => {
              const response = await loadGlobalWorkspaces(params);

              const options = response.items.map((item) => ({
                value: item.id,
                label: item.name,
              }));

              return options;
            }}
          >
            {(selectAsyncProps) => (
              <Select.Quick
                name="maintenance_id"
                {...commonProps}
                {...selectAsyncProps}
                {...{ ...commonProps, disabled: commonProps.disabled || detail }}
              />
            )}
          </Select.Async>
        </PageAddon.Field>
      ),
      params_size: (
        <PageAddon.Field label={t('common.params')}>
          <Textarea.Quick name="params_size" {...commonProps} />
        </PageAddon.Field>
      ),
      params_size_hu: (
        <PageAddon.Field label={t('common.params_hu')}>
          <Textarea.Quick name="params_size_hu" {...commonProps} />
        </PageAddon.Field>
      ),
      description: (
        <PageAddon.Field label={t('common.description')}>
          <Textarea.Quick name="description" {...commonProps} />
        </PageAddon.Field>
      ),
    },
  };
};

export default useForm;
