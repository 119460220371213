import { useState } from 'react';
import { BotSettingRuleSchema } from '../../api/schemas/botSettingRuleSchema';
import Dropdown from '../../components/Dropdown';
import GlobalBotSettingRuleChangeNameDialog from './GlobalBotSettingRuleChangeNameDialog';
import useDropdown from './useDropdown';

interface Props {
  data: BotSettingRuleSchema;
}

const GlobalBotSettingRuleDropdown = (props: Props) => {
  const { data } = props;

  const [open, setOpen] = useState(false);

  const { title, disabled, options } = useDropdown({
    data,
    changeName: () => setOpen(true),
  });

  return (
    <>
      {open && <GlobalBotSettingRuleChangeNameDialog data={data} onClose={() => setOpen(false)} />}
      <Dropdown
        title={title}
        buttonSize="small"
        disabled={disabled}
        options={[options.changeName, options.copy, options.divider, options.delete]}
      />
    </>
  );
};

export default GlobalBotSettingRuleDropdown;
