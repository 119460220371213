import api from '../../services/api';
import { BotSettingSchema } from '../schemas/botSettingSchema';
import emptyResponseSchema from '../schemas/emptyResponseSchema';

type Schema = BotSettingSchema;

interface Payload {
  name?: Schema['name'];
  bot_code?: Schema['bot_code'];
  bot_build?: Schema['bot_build'];
  bot_build_straddle?: Schema['bot_build_straddle'];
  bot_code_tight?: Schema['bot_code_tight'];
  bot_build_tight?: Schema['bot_build_tight'];
  params_size?: Schema['params_size'];
  params_size_hu?: Schema['params_size_hu'];
  description?: Schema['description'];
}

interface Args {
  botSettingId: Schema['id'];
  payload: Payload;
}

const updateGlobalBotSetting = async (args: Args) => {
  const { botSettingId, payload } = args;

  const response = await api.patch(`/api/bot-settings/${botSettingId}`, payload);

  return emptyResponseSchema.parse(response.data);
};

export default updateGlobalBotSetting;
