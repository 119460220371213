import { useTranslation } from 'react-i18next';
import { BotSettingRuleSchema } from '../../api/schemas/botSettingRuleSchema';
import Form from '../../components/Form';
import PageAddon from '../../components/PageAddon';
import SubmitButton from '../../components/SubmitButton';
import useForm from './useForm';

interface Props {
  onClose: () => void;
  onCreate: (args: { botSettingRuleId: BotSettingRuleSchema['id'] }) => void;
}

const GlobalBotSettingRuleForm = (props: Props) => {
  const { onClose, onCreate } = props;

  const { t } = useTranslation();

  const { form, fields, onSubmit } = useForm({ onCreate });

  return (
    <PageAddon title={t('common.create_usage_rule')} onClose={onClose}>
      <Form form={form} onSubmit={onSubmit}>
        {fields.name}
        {fields.game_type}
        {fields.maintenance_id}
        {fields.bot_settings_id}
        {fields.poker_room}
        {fields.description}
        <PageAddon.Controls>
          <SubmitButton>{t('common.create')}</SubmitButton>
        </PageAddon.Controls>
      </Form>
    </PageAddon>
  );
};

export default GlobalBotSettingRuleForm;
