import api from '../../services/api';
import botSettingRuleSchema, { BotSettingRuleSchema } from '../schemas/botSettingRuleSchema';

type Schema = BotSettingRuleSchema;

interface Payload {
  name: Schema['name'];
  game_type: Schema['game_type'];
  maintenance_id: Exclude<Schema['maintenance'], null>['id'] | null;
  bot_settings_id: Schema['bot_settings']['id'];
  poker_room: Schema['poker_room'];
  description: Schema['description'];
}

interface Args {
  payload: Payload;
}

const createGlobalBotSettingRule = async (args: Args) => {
  const { payload } = args;

  const response = await api.post('/api/bot-settings-usage-rule', payload);

  return botSettingRuleSchema.pick({ id: true }).parse(response.data);
};

export default createGlobalBotSettingRule;
